import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./normalize.scss";
import "./theme/base.scss";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
