import { useContext } from "react";

import { ProductsListType } from "./productsList.types";
import { ProductsListContext } from "./ProductsListProvider";

const useProductsList = (): ProductsListType => {
  const context = useContext(ProductsListContext);

  if (context === undefined) {
    throw new Error("useProductsList must be used within a provider");
  }

  return context;
};

export default useProductsList;
