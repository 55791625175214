import React, { FC } from "react";
import axios from "axios";
import { configure } from "axios-hooks";

import { ISalesTerminalTypeId, ConfigClientEnum, IMemberVenueId } from "@interfaces/enums/apiData.enum";
import { IAppKey } from "@interfaces/types/apiData.type";
import useConfig from "./providers/configProvider/useConfig";

const AxiosClient: FC = ({ children }) => {
  const { config } = useConfig();
  const data: IAppKey = {
    MemberVenueId: IMemberVenueId[config?.client || ConfigClientEnum.sn],
    SalesTerminalTypeId: ISalesTerminalTypeId[config?.client || ConfigClientEnum.sn],
    LocalTimeZone: "Pacific Standard Time",
  };

  const appKey: string = btoa(JSON.stringify(data));
  const instance = axios.create({
    baseURL: config?.API_URI,
  });
  instance.interceptors.request.use((axiosConfig) => {
    const interceptorConfig = { ...axiosConfig };
    interceptorConfig.headers = {
      appKey,
    };
    return interceptorConfig;
  });
  configure({ axios: instance, cache: false });

  return <>{children}</>;
};

export default AxiosClient;
