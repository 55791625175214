import React, { FC } from "react";

import { ILoaderProps } from "./loader.types";
import "./loader.scss";

const Loader: FC<ILoaderProps> = ({ isAbsolute, isHeightFull, isWidthFull, blurBg, isCandy }) => (
  <div
    className={`
      loader-wrapper
      ${blurBg && "loader-wrapper__blured"}
      ${isHeightFull && "loader-wrapper__full-height"}
      ${isWidthFull && "loader-wrapper__full-width"}
      ${isAbsolute && "loader-wrapper__absolute"}
      ${isCandy && "loader-wrapper__fixed"}
    `}
  >
    {isCandy ? (
      <div className="loader__content">
        <div className="loader__title">Please wait...</div>
        <div className="loader__candy" />
      </div>
    ) : (
      <div className="loader" />
    )}
  </div>
);

export default Loader;
