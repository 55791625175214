import React, { FC } from "react";

import { ConfigProvider } from "./providers/configProvider/ConfigProvider";
import ConfigChecker from "./ConfigChecker";
import "./app.scss";

const App: FC = () => (
  <ConfigProvider>
    <ConfigChecker />
  </ConfigProvider>
);

export default App;
