export enum ProductGroupNameEnum {
  products = "products",
  productsOtherAttractions = "productsOtherAttractions",
  productsEvents = "productsEvents",
  productsGroups = "productsGroups",
  promoCodeProducts = "promoCodeProducts",
}

export enum ProductGroupNameRoutes {
  products = "main",
  productsOtherAttractions = "other-attractions",
  productsEvents = "events",
  productsGroups = "groups",
  promoCodeProducts = "promo-code",
}

export enum TabNameEnum {
  products = "TICKETS",
  productsOtherAttractions = "OTHER ATTRACTIONS",
  productsEvents = "EVENTS",
  productsGroups = "GROUPS",
  promoCodeProducts = "PROMO CODE",
}
