import ViewTicketsDto from "@interfaces/rest/viewTickets.dto";

export type IViewTicketsType = IViewTicketsState;

export enum ViewErrorCodeEnum {
  NO_TICKET = "NO_TICKET",
  CONFIRMATION_NOT_VALID = "CONFIRMATION_NOT_VALID",
  UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
}

export interface IViewTicketsState {
  isLoading: boolean;
  ticketsData: ViewTicketsDto[] | null;
  step: number;
  scrollCarousel: (forValue: number) => void;
  ticketTermsDataToShow: string;
  handleShowMoreInfoChange: (text: string) => void;
  viewError: ViewErrorCodeEnum | null;
  handleResetError: VoidFunction;
}
