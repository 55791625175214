import React, { FC, createContext, useMemo } from "react";

import useCartState from "./useCartState";

const CartContext = createContext<any | undefined>(undefined);

const CartProvider: FC = ({ children }) => {
  const {
    isLoading,
    addProduct,
    products,
    isGroupSales,
    removeProduct,
    orderData,
    isOrderError,
    handleErrorClean,
    updateCartProduct,
    handleCartErase,
    handlePurchase,
    handleConfirmOrderPromo,
  } = useCartState();

  const value = useMemo(
    () => ({
      isLoading,
      addProduct,
      products,
      isGroupSales,
      removeProduct,
      orderData,
      isOrderError,
      handleErrorClean,
      updateCartProduct,
      handleCartErase,
      handlePurchase,
      handleConfirmOrderPromo,
    }),
    [isLoading, products, isGroupSales, orderData, isOrderError],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export { CartProvider, CartContext };
