import { useCallback, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useSearchParams } from "react-router-dom";
import ViewTicketsDto from "@interfaces/rest/viewTickets.dto";

import api from "@api/index";
import { IViewTicketsState, ViewErrorCodeEnum } from "./viewTickets.types";

const useViewTicketsState = (): IViewTicketsState => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ticketsData, setTicketsData] = useState<ViewTicketsDto[] | null>(null);
  const [step, setStep] = useState<number>(0);
  const [ticketTermsDataToShow, setTicketTermsDataToShow] = useState<string>("");
  const [viewError, setViewError] = useState<ViewErrorCodeEnum | null>(null);

  const [{}, getViewTickets] = useAxios<ViewTicketsDto[]>(
    {
      url: api.getViewTickets,
      method: "get",
    },
    { manual: true },
  );

  const fetchOrderTickets = useCallback(async (orderConfirmation: string): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await getViewTickets({
        params: {
          orderConfirmation,
        },
      });

      if (!response.data.length) {
        setViewError(ViewErrorCodeEnum.NO_TICKET);
      } else {
        setTicketsData(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      setViewError(ViewErrorCodeEnum.UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const orderConfirmation: string | null = searchParams.get("orderConfirmation");
    if (!orderConfirmation) {
      setViewError(ViewErrorCodeEnum.CONFIRMATION_NOT_VALID);
      return;
    }
    fetchOrderTickets(orderConfirmation);
  }, [searchParams]);

  const scrollCarousel = useCallback(
    (forValue: number) => {
      if (ticketsData) {
        const value: number = step + forValue;
        const isScrollingToLast: boolean = value < 0;
        const nextValue: number = value > ticketsData.length - 1 ? 0 : value;
        setStep(isScrollingToLast ? ticketsData.length - 1 : nextValue);
      }
    },
    [step, ticketsData],
  );

  const handleShowMoreInfoChange = useCallback(
    (text: string) => {
      setTicketTermsDataToShow(text);
    },
    [ticketTermsDataToShow],
  );

  const handleResetError = useCallback(() => {
    setViewError(null);
  }, []);

  return {
    isLoading,
    ticketsData,
    step,
    scrollCarousel,
    ticketTermsDataToShow,
    handleShowMoreInfoChange,
    viewError,
    handleResetError,
  };
};

export default useViewTicketsState;
