import { format } from "date-fns";

import {
  IInventoryTimeOfDayName,
  IProductInventoryResult,
  IProductAndPromotionsDto,
  IProductAndPromotions,
  IProductInventoryModels,
  IInventoryTimeOfDaySlots,
  IProductInventoryModelsDated,
  IPromotionsDto,
  IPromotions,
  ISlotStructPriceAdjustmentModels,
  ISlotTotalAdjustment,
  IInventoryTimeOfDay,
  IPromoAdjustments,
  IProductInvTimeOfDayPrice,
  IInvTimeOfDayPrices,
} from "@interfaces/types/productInventory.type";
import { ISelectedTicket, ITicketDataTimeSlot } from "@pages/product/provider/product.types";
import { ISlotStructureLabel } from "@interfaces/rest/viewTickets.dto";

import { calculateSubTotalByTicketTypes } from "@utils/calculateSubTotal";
import getMultiTimedProductPromotionAdjustment from "@utils/getMultiTimedProductPromotionAdjustment";
import ProductInventoryDto from "../rest/productInventory.dto";

class ProductInventoryUI {
  public productInventoryResult: IProductInventoryResult;

  public productAndPromotions: IProductAndPromotions;

  public isAvailable: boolean;

  constructor(data?: ProductInventoryDto) {
    if (data) {
      this.productInventoryResult = {
        ...data.productInventoryResult,
        inventoryTimeOfDay: this.formInventoryTimeOfDayByProductId(
          data.productInventoryResult.productInventoryModels,
          data.productInventoryResult.slotStructPriceAdjustmentModels,
        ),
      };
      this.productAndPromotions = {
        ...data.productAndPromotions,
        promotions: this.formProductPromotions(
          data.productAndPromotions,
          data.productInventoryResult.slotStructPriceAdjustmentModels,
          data.productInventoryResult.productInventoryModels,
        ),
      };
      this.isAvailable = data.isAvailable;
    }
  }

  private formTimeOfDay(
    productInventoryModels: IProductInventoryModels[],
    slotStructPriceAdjustmentModels: ISlotStructPriceAdjustmentModels[],
  ): IInventoryTimeOfDaySlots[] {
    const productInventory: IProductInventoryModelsDated[] = productInventoryModels.map(
      (inventoryItem: IProductInventoryModels) => ({
        ...inventoryItem,
        startDate: new Date(inventoryItem.start),
        endDate: new Date(inventoryItem.end),
      }),
    );

    const uniqueSlotStructureId: IInventoryTimeOfDayName[] = productInventory.reduce(
      (prev: IInventoryTimeOfDayName[], cur: IProductInventoryModelsDated) => {
        const isAlreadyContainsTimeOfDay: boolean =
          prev.findIndex((item: IInventoryTimeOfDayName) => item.slotStructureId === cur.slotStructureId) !== -1;

        return isAlreadyContainsTimeOfDay ? prev : [...prev, { slotStructureId: cur.slotStructureId, name: cur.name }];
      },
      [],
    );

    return uniqueSlotStructureId.reduce((array: IInventoryTimeOfDaySlots[], invObj: IInventoryTimeOfDayName) => {
      const slotStructureIdInventory: IProductInventoryModelsDated[] = productInventory.filter(
        (inventoryItem: IProductInventoryModelsDated) => inventoryItem.slotStructureId === invObj.slotStructureId,
      );

      const startDates: Date[] = slotStructureIdInventory.map(
        (structureIdInv: IProductInventoryModelsDated) => structureIdInv.startDate,
      );

      const endDates: Date[] = slotStructureIdInventory.map(
        (structureIdInv: IProductInventoryModelsDated) => structureIdInv.endDate,
      );

      const adjustments: ISlotStructPriceAdjustmentModels[] = slotStructPriceAdjustmentModels.filter(
        (adjModel: ISlotStructPriceAdjustmentModels) => adjModel.slotStructureId === invObj.slotStructureId,
      );

      const slotTotalAdjustment: ISlotTotalAdjustment = adjustments.reduce(
        (obj: ISlotTotalAdjustment, adjustment: ISlotStructPriceAdjustmentModels) => {
          obj[adjustment.slotStructureId] = obj[adjustment.slotStructureId]
            ? obj[adjustment.slotStructureId] + adjustment.adjustment
            : adjustment.adjustment;
          return obj;
        },
        {},
      );

      const slotIds: number[] = Object.keys(slotTotalAdjustment).map((id: string) => +id);

      const adjustmentValues: number[] = slotIds.map((id: number) => slotTotalAdjustment[id]);

      const minAdjustment: number = Math.min(...adjustmentValues);

      return [
        ...array,
        {
          ...invObj,
          from: format(new Date(startDates.reduce((a: Date, b: Date) => (a < b ? a : b))), "h aaa"),
          to: format(new Date(endDates.reduce((a: Date, b: Date) => (a > b ? a : b))), "h aaa"),
          inventory: slotStructureIdInventory,
          priceAdjustment: minAdjustment === Infinity ? 0 : minAdjustment,
        },
      ];
    }, []);
  }

  private formInventoryTimeOfDayByProductId(
    productInventoryModels: IProductInventoryModels[],
    slotStructPriceAdjustmentModels: ISlotStructPriceAdjustmentModels[],
  ): IInventoryTimeOfDay {
    const productIds: number[] = productInventoryModels
      .map((model: IProductInventoryModels) => model.productId)
      .filter((value, index, self) => self.indexOf(value) === index);

    return productIds.reduce((obj: IInventoryTimeOfDay, id: number) => {
      const productModels: IProductInventoryModels[] = productInventoryModels.filter((model) => model.productId === id);

      return {
        ...obj,
        [id]: this.formTimeOfDay(productModels, slotStructPriceAdjustmentModels),
      };
    }, {});
  }

  formProductTimeOfDayPrice(ticketsTypes: ISelectedTicket[], productId: number): IProductInvTimeOfDayPrice[] {
    const generalPrice: number = calculateSubTotalByTicketTypes(ticketsTypes) || 0;

    const productInventory: IInventoryTimeOfDaySlots[] | undefined =
      this.productInventoryResult.inventoryTimeOfDay[productId];

    const invTimeOfDayPrice: IProductInvTimeOfDayPrice[] = productInventory?.map(
      (timeOfDay: IInventoryTimeOfDaySlots) => {
        const slotAdjustmentsModels: ISlotStructPriceAdjustmentModels[] | undefined =
          this.productInventoryResult.slotStructPriceAdjustmentModels
            .filter((obj: ISlotStructPriceAdjustmentModels) => obj.slotStructureId === timeOfDay.slotStructureId)
            .filter((obj: ISlotStructPriceAdjustmentModels) =>
              this.productAndPromotions.isMultiTimed ? obj.childProduct === productId : true,
            );

        const slotStructureAdjustmentTotal: number =
          ticketsTypes?.reduce((total: number, cur: ISelectedTicket) => {
            const slotModelsByPromoId: ISlotStructPriceAdjustmentModels[] | undefined = slotAdjustmentsModels?.filter(
              (obj: ISlotStructPriceAdjustmentModels) => obj.promotionId === cur.id,
            );

            const adjustmentForSlotStructure: number =
              slotModelsByPromoId?.reduce((totalForSlot: number, obj) => (totalForSlot += obj.adjustment), 0) || 0;

            return total + cur.selectedCount * adjustmentForSlotStructure;
          }, 0) || 0;

        return {
          slotStructureId: timeOfDay.slotStructureId,
          totalPrice: generalPrice + slotStructureAdjustmentTotal,
        };
      },
    );

    return invTimeOfDayPrice;
  }

  getTimeOfDayProductsPricesList(ticketsTypes: ISelectedTicket[]): IInvTimeOfDayPrices {
    const invIds: number[] = Object.keys(this.productInventoryResult.inventoryTimeOfDay).map((id: string) => +id);
    const timeOfDayPricesByProduct: IInvTimeOfDayPrices = invIds.reduce(
      (obj: IInvTimeOfDayPrices, id: number): IInvTimeOfDayPrices => ({
        ...obj,
        [id]: this.formProductTimeOfDayPrice(ticketsTypes, id),
      }),
      {},
    );

    return timeOfDayPricesByProduct;
  }

  getMultiTimedDiscount(ticketsTypes: ISelectedTicket[], timeSlots: ITicketDataTimeSlot): number {
    const selectedTimeOfDayIds: number[] = Object.keys(timeSlots).map((id: string) => +id);

    return selectedTimeOfDayIds.reduce((totalDiscount: number, productId: number) => {
      const slotAdjustmentsModels: ISlotStructPriceAdjustmentModels[] | undefined =
        this.productInventoryResult.slotStructPriceAdjustmentModels.filter(
          (obj: ISlotStructPriceAdjustmentModels) =>
            obj.childProduct === productId && obj.slotStructureId === timeSlots[productId]?.slotStructureId,
        );

      const slotStructureAdjustmentTotal: number = ticketsTypes.reduce((total: number, cur: ISelectedTicket) => {
        const slotModelsByPromoId: ISlotStructPriceAdjustmentModels[] | undefined = slotAdjustmentsModels?.filter(
          (obj: ISlotStructPriceAdjustmentModels) => obj.promotionId === cur.id,
        );

        const adjustmentForSlotStructure: number = slotModelsByPromoId?.reduce(
          (totalForSlot: number, obj) => (totalForSlot += obj.adjustment),
          0,
        );

        return total + cur.selectedCount * adjustmentForSlotStructure;
      }, 0);

      return totalDiscount + slotStructureAdjustmentTotal;
    }, 0);
  }

  private formProductPromotions(
    productAndPromotions: IProductAndPromotionsDto,
    slotStructPriceAdjustmentModels: ISlotStructPriceAdjustmentModels[],
    productInventoryModels: IProductInventoryModels[],
  ): IPromotions[] {
    const regularTicket: IPromotions = {
      id: 0,
      name: productAndPromotions.displayLabel,
      shortDescription: productAndPromotions.displayLabel,
      longDescription: productAndPromotions.displayLabel,
      imageUrl: "",
      type: "",
      value: 0,
      unit: "",
      timedYN: productAndPromotions.timedYN,
      inclusions: [],
      requiredValidationYN: false,
      promoType: 0,
      seasonalPromoPriceAdjustment: 0,
      price: productAndPromotions.retailPrice,
    };
    const promotionsPriced: IPromotions[] = productAndPromotions.promotions.map((promo: IPromotionsDto) => ({
      ...promo,
      price: productAndPromotions.retailPrice + promo.value,
    }));
    promotionsPriced.unshift(regularTicket);

    const slotStructPriceAdjustmentModelsFiltered: ISlotStructPriceAdjustmentModels[] =
      slotStructPriceAdjustmentModels.filter((adjModel: ISlotStructPriceAdjustmentModels) =>
        productInventoryModels.some(
          (invModel: IProductInventoryModels) => invModel.slotStructureId === adjModel.slotStructureId,
        ),
      );

    const promotionsFinalized: IPromotions[] = promotionsPriced.map((promo: IPromotions) => {
      const adjustments: ISlotStructPriceAdjustmentModels[] = slotStructPriceAdjustmentModelsFiltered.filter(
        (adjModel: ISlotStructPriceAdjustmentModels) => adjModel.promotionId === promo.id,
      );

      const slotTotalAdjustment: ISlotTotalAdjustment = adjustments.reduce(
        (obj: ISlotTotalAdjustment, adjustment: ISlotStructPriceAdjustmentModels) => {
          obj[adjustment.slotStructureId] = (obj[adjustment.slotStructureId] || 0) + adjustment.adjustment;
          return obj;
        },
        {},
      );

      const slotIds: number[] = productInventoryModels
        .map((item: IProductInventoryModels) => item.slotStructureId)
        .filter((value: number, index: number, array: number[]) => array.indexOf(value) === index);

      const adjValuesSingle: number[] = slotIds.map((id: number) => slotTotalAdjustment?.[id] || 0);
      const adjValues = productAndPromotions.isMultiTimed ? adjValuesSingle : adjValuesSingle;

      const minAdjSingle = adjValues.length ? Math.min(...adjValues) : 0;
      const maxAdjSingle = adjValues.length ? Math.max(...adjValues) : 0;
      const { minAdjValue: minAdjMultiTimed, maxAdjValue: maxAdjMultiTimed }: IPromoAdjustments =
        getMultiTimedProductPromotionAdjustment(productInventoryModels, slotStructPriceAdjustmentModels, promo.id);
      const minAdj: number = productAndPromotions.isMultiTimed ? minAdjMultiTimed : minAdjSingle;
      const maxAdj: number = productAndPromotions.isMultiTimed ? maxAdjMultiTimed : maxAdjSingle;
      const minPrice = minAdj ? promo.price + minAdj : promo.price;
      const maxPrice = maxAdj ? promo.price + maxAdj : promo.price;

      return {
        ...promo,
        minPrice,
        maxPrice,
      };
    });

    return promotionsFinalized;
  }

  getSlotStructureLabelByProductId(productId: number): ISlotStructureLabel | undefined {
    return this.productInventoryResult.slotStructureLabels.find(
      (item: ISlotStructureLabel) => item.productId === productId,
    );
  }
}

export default ProductInventoryUI;
