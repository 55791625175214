import { useCallback, useEffect, useState } from "react";
import useAxios from "axios-hooks";
import { useNavigate, useMatch } from "react-router-dom";

import api from "@api/index";
import ProductsListUI, { ProductUI } from "@interfaces/ui/productList.ui";
import ProductsListDto from "@interfaces/rest/productsList.dto";
import PromoCodeProductDto from "@interfaces/rest/promoCodeProduct.dto";
import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";
import convertPromoProductInstance from "@utils/convertPromocodeProductInstance";
import { getProductFromFullListById } from "@utils/getProductInstance";
import HyperLinkDataDto from "@interfaces/rest/hyperLinkData.dto";

import { IProductsList, IProductsListState, ICheckoutData, PromoCodeModeEnum } from "./productsList.types";

const useProductsListState = (): IProductsListState => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPromoInvalid, setIsPromoInvalid] = useState<boolean>(false);
  const [productsList, setProductsList] = useState<IProductsList | null>(null);
  const [checkoutData, setCheckoutData] = useState<ICheckoutData | null>(null);
  const isViewTicketsPage = useMatch("/view-tickets");

  const [{}, getProductsList] = useAxios<ProductsListDto>(
    {
      url: api.productsWithUpgrades,
      method: "get",
    },
    { manual: true },
  );

  const [{}, getPromoCodeProduct] = useAxios<PromoCodeProductDto[]>(
    {
      method: "get",
    },
    { manual: true },
  );

  const [{}, getHyperLinkProduct] = useAxios<HyperLinkDataDto>(
    {
      method: "get",
    },
    { manual: true },
  );

  useEffect(() => {
    if (isViewTicketsPage) {
      return;
    }

    setIsLoading(true);
    getProductsList()
      .then(({ data }) => {
        const { products, productsEvents, productsGroups, productsOtherAttractions, promoCodeProducts } =
          new ProductsListUI(data);

        setProductsList({
          products,
          productsEvents,
          productsGroups,
          productsOtherAttractions,
          promoCodeProducts,
        });
        setCheckoutData({
          pricingNotice: data.pricingNotice,
          checkOutThankMessage: data.checkOutThankMessage,
          checkOutUpgradeMessage: data.checkOutUpgradeMessage,
          checkOutMessage: data.checkOutMessage,
        });
      })
      .catch((err) => {
        console.error("getProductsList error: ", err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const resetPromoError = useCallback(() => {
    setIsPromoInvalid(false);
  }, []);

  const getProductsByPromo = useCallback(
    async (promoCode: string, promoCodeMode: PromoCodeModeEnum) => {
      if (!productsList) {
        return;
      }
      try {
        setIsLoading(true);
        resetPromoError();
        const response = await getPromoCodeProduct({
          url: api.productsByPromocode(promoCode, promoCodeMode),
        });

        if (!response.data?.length) {
          setIsPromoInvalid(true);
          setIsLoading(false);
          return;
        }
        const products: ProductUI[] = convertPromoProductInstance(
          response.data,
          ProductGroupNameEnum.promoCodeProducts,
        );
        setProductsList({
          ...productsList,
          promoCodeProducts: products,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    [productsList],
  );

  const handleDirectProductPageOpen = useCallback(
    async (id: string) => {
      try {
        setIsLoading(true);

        const hyperLinkProdData = await getHyperLinkProduct({
          url: api.getProductByHyperLink(id),
        });

        if (hyperLinkProdData.data.isExpiredLink || !hyperLinkProdData.data.id || !productsList) {
          return navigate("/");
        }

        const presentProduct: ProductUI | undefined = getProductFromFullListById(
          productsList,
          hyperLinkProdData.data.id,
        );

        if (presentProduct) {
          return navigate(
            `/product?productId=${presentProduct.productId}&type=${presentProduct.productCategoryName}&directAccess=${id}`,
          );
        }

        const response = await getPromoCodeProduct({
          url: api.productsByPromocode(id, PromoCodeModeEnum.ProductPromoHyperlink),
        });

        const directProducts: ProductUI[] = convertPromoProductInstance(
          response.data,
          ProductGroupNameEnum.products,
          true,
        );

        if (!directProducts.length) {
          return navigate("/");
        }

        if (productsList) {
          setProductsList({
            ...productsList,
            products: [...productsList.products, ...directProducts],
          });
          setIsLoading(false);
          const selectedProd: ProductUI = directProducts[0];
          const searchParams: string = selectedProd.voucherId
            ? `&voucherId=${selectedProd.voucherId}`
            : `&directAccess=${id}`;

          navigate(
            `/product?productId=${selectedProd.productId}&type=${selectedProd.productCategoryName}${searchParams}`,
          );
        }

        setIsLoading(false);
      } catch (error) {
        navigate(`/?directAccess=${id}`);
        setIsLoading(false);
      }
    },
    [productsList],
  );

  const calculatePruductCategoryById = useCallback(
    (productId: number): ProductGroupNameEnum | null => {
      if (!productsList) {
        return null;
      }

      const product: ProductUI | undefined = getProductFromFullListById(productsList, productId);

      return product ? product.productCategoryName : null;
    },
    [productsList],
  );

  return {
    productsList,
    checkoutData,
    isLoading,
    getProductsByPromo,
    isPromoInvalid,
    resetPromoError,
    calculatePruductCategoryById,
    handleDirectProductPageOpen,
  };
};

export default useProductsListState;
