import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";
import ProductInventoryUI from "@interfaces/ui/productInventory.ui";
import { IProductInventoryModelsDated } from "@interfaces/types/productInventory.type";
import { ProductUI } from "@interfaces/ui/productList.ui";
import CartProductUI from "@interfaces/ui/cartProduct.ui";
import IOrderDataDto from "@interfaces/rest/orderData.dto";
import { IAppliedPromotionsCitypass } from "@pages/cart/provider/cart.types";
import { IBarcodeState } from "../components/ticketData/components/cityPassBarcodePicker/citypassBarcodePicker.types";

export type ProductType = IProductState;

export interface IProductState {
  isLoading: boolean;
  product: ProductUI | null;
  cityPassProduct: ProductUI | null;
  isCityPassExchange: boolean;
  productDates: string[] | null;
  ticketData: ITicketData | null;
  fetchProductDates: (prodType: ProductGroupNameEnum, cartProduct?: CartProductUI) => Promise<void>;
  productInventory: ProductInventoryUI | null;
  handleAddTicketData: (newData: ITicketData, newStep?: TicketStepsEnum) => void;
  step: number;
  ticketTotal: number;
  barcodes: IBarcodeState[] | null;
  addCitypassBarcodeInstance: VoidFunction;
  handleCitypassBarcodeChange: (id: string, value: string) => void;
  isExchangeOrdering: boolean;
  handleExchangeOrder: VoidFunction;
  removeCitypassBarcode: (id: string) => void;
  exchangeOrderData: IOrderDataDto | null;
}

export interface ITicketDataTimeOfDay {
  [key: number]: number;
}

export interface ITicketDataTime {
  slotStructureId: number;
  time?: IProductInventoryModelsDated;
}

export interface ITicketDataTimeSlot {
  [key: number]: ITicketDataTime;
}

export interface ITicketData {
  visitDate?: Date;
  ticketsTypes?: ISelectedTicket[] | null;
  timeSlot?: ITicketDataTimeSlot;
  subTotal?: number;
}

export interface ISelectedTicket {
  id: number;
  name: string;
  price: number;
  selectedCount: number;
  type: string;
  unit: string;
  value: number;
  totalAdjustmentBySlot?: number;
}

export interface ISelectedTimeSlot {
  id: number;
  start: string;
  end: string;
  isInventoryLow: boolean;
  name: string;
  productId: number;
  quantity: number;
  showStartTimeOnly: boolean;
  slotStructureId: number;
}

export enum TicketStepsEnum {
  DEFAULT = 1,
  VISIT_DATE = 2,
  TICKETS = 3,
  TIME_OF_DAY = 4,
  ENTRY_TIME = 5,
}

export interface ILocationState {
  editCartId?: string | null;
  isUpgrading?: string | null;
  ticketDataDefault?: ITicketData | null;
}

export interface IConfirmOrderPromoData {
  email: string;
  firstName: string;
  lastName: string;
  postalCode: string;
  phoneNumber: string;
  isMobileOrTabletDevice: boolean;
  sendNotificationYN: boolean;
  externalBarcodes?: string[];
  barcodeProvider?: string;
}

export interface IFetchCitypassProductResult {
  isValid: boolean;
  appliedPromotions?: IAppliedPromotionsCitypass[] | null;
}

export const cityPassC3NameRegExp = /(?=.*citypass)(?=.*c3)/i;
export const cityPassC3BarcodeStart = "30845878";
