import { IInvTimeOfDayPrices, IProductInvTimeOfDayPrice } from "@interfaces/types/productInventory.type";
import { ISelectedTicket, ITicketDataTimeSlot } from "@pages/product/provider/product.types";

export const calculateSubTotalByTicketTypes = (ticketTypes: ISelectedTicket[]): number =>
  ticketTypes.reduce((total: number, obj: ISelectedTicket) => (total += obj.selectedCount * obj.price), 0);

export const getSubTotalFromInventory = (
  timeOfDay: ITicketDataTimeSlot,
  timeOfDayPriceList: IInvTimeOfDayPrices,
): number => {
  const selectedInvIds: number[] = Object.keys(timeOfDay).map((id: string) => +id);
  const price: number = selectedInvIds.reduce((total: number, timeOfDayId: number) => {
    const priceList: IProductInvTimeOfDayPrice[] | undefined = timeOfDayPriceList?.[timeOfDayId];

    const totalTimeOfDayPrice: number =
      priceList?.find(
        (invObj: IProductInvTimeOfDayPrice) => invObj.slotStructureId === timeOfDay?.[timeOfDayId].slotStructureId,
      )?.totalPrice || 0;

    return total + totalTimeOfDayPrice;
  }, 0);

  return price;
};

export const calculateTicketsCount = (ticketsTypes: ISelectedTicket[]): number =>
  ticketsTypes.reduce((prodCount: number, ticket: ISelectedTicket) => prodCount + ticket.selectedCount, 0);
