import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { ClientDomainEnum } from "@interfaces/enums/apiData.enum";
import { IApiDataConfig } from "@interfaces/types/apiData.type";
import IOrderDataDto from "@interfaces/rest/orderData.dto";
import CartProductUI from "@interfaces/ui/cartProduct.ui";
import { TabNameEnum } from "@interfaces/enums/tabName.enum";
import { ProductUI } from "@interfaces/ui/productList.ui";
import { getCartTickets, getProductTickets, getCityPassProductItems } from "@utils/gaUtils";
import { ITrackingItems } from "@interfaces/types/tracking.types";

import { ITrackingState } from "./tracking.types";

const useTrackingState = (config?: IApiDataConfig | null): ITrackingState => {
  const location = useLocation();

  useEffect(() => {
    try {
      ReactGA.initialize(config?.gaMeasurementId || "");

      if (config?.client) {
        const url = `${document.location.protocol === "https:" ? "https://" : "http://"}beacon.riskified.com?shop=${
          ClientDomainEnum[config?.client]
        }&sid=${config.cartToken}`;
        const scriptElement = document.createElement("script");
        scriptElement.type = "text/javascript";
        scriptElement.async = true;
        scriptElement.src = url;
        window.document.body.appendChild(scriptElement);
        const firstScript = document.getElementsByTagName("script")[0];
        const scriptContainer = firstScript?.parentNode || document.head;
        scriptContainer.insertBefore(scriptElement, firstScript);
      }
    } catch (error) {
      console.log("google analytics initialization error", error);
    }
  }, []);

  useEffect(() => {
    (window as any)?.RISKX?.go(location.pathname);
  }, [location.pathname]);

  const trackViewItemList = (id: string, name: TabNameEnum, products: ProductUI[]): void => {
    ReactGA.gtag("event", "view_item_list", {
      item_list_id: id,
      item_list_name: name,
      items: products.map((prod) => ({
        item_id: prod.productId,
        item_name: prod.name,
        item_list_id: id,
        item_list_name: name,
      })),
    });
  };

  const trackPageView = (productItem: ProductUI, price: number): void => {
    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: price,
      items: [
        {
          item_id: productItem.productId,
          item_name: productItem.name,
          item_category: TabNameEnum[productItem.productCategoryName],
          price,
        },
      ],
    });
  };

  const trackAddingToCart = (newProduct: CartProductUI): void => {
    const items: ITrackingItems[] = getProductTickets(newProduct);

    ReactGA.gtag("event", "add_to_cart", {
      currency: "USD",
      value: newProduct.productPrice,
      items,
    });
  };

  const trackCityPassAddToCart = (orderData: IOrderDataDto, product: CartProductUI): void => {
    const items = getCityPassProductItems(orderData.ticketDataResponse || [], product);

    ReactGA.gtag("event", "add_to_cart", {
      currency: "USD",
      value: orderData.totalPrice,
      tax: orderData.totalTax,
      items,
    });
  };

  const trackRemovingFromCart = (removedProduct: CartProductUI): void => {
    const items: ITrackingItems[] = getProductTickets(removedProduct);

    ReactGA.gtag("event", "remove_from_cart", {
      currency: "USD",
      value: removedProduct.productPrice,
      items,
    });
  };

  const trackPurchase = (orderData: IOrderDataDto, products: CartProductUI[]): void => {
    const items: ITrackingItems[] = getCartTickets(products);

    ReactGA.gtag("event", "purchase", {
      currency: "USD",
      transaction_id: orderData.orderId,
      value: orderData.totalPrice,
      tax: orderData.totalTax,
      items,
    });
  };

  const trackCartView = (price: number, products: CartProductUI[]): void => {
    const items: ITrackingItems[] = getCartTickets(products);

    ReactGA.gtag("event", "view_cart", {
      currency: "USD",
      value: price,
      items,
    });
  };

  const trackCheckoutStart = (price: number, products: CartProductUI[]): void => {
    const items: ITrackingItems[] = getCartTickets(products);

    ReactGA.gtag("event", "begin_checkout", {
      currency: "USD",
      value: price,
      items,
    });
  };

  const trackPaymentInfoAdd = (price: number, products: CartProductUI[]): void => {
    const items: ITrackingItems[] = getCartTickets(products);

    ReactGA.gtag("event", "add_payment_info", {
      currency: "USD",
      value: price,
      payment_type: "Credit Card",
      items,
    });
  };

  return {
    trackPageView,
    trackPurchase,
    trackAddingToCart,
    trackRemovingFromCart,
    trackViewItemList,
    trackCartView,
    trackCheckoutStart,
    trackPaymentInfoAdd,
    trackCityPassAddToCart,
  };
};

export default useTrackingState;
