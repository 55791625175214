import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import { ISlotStructPriceAdjustmentModels } from "@interfaces/types/productInventory.type";
import { ISelectedTicket, ITicketData, ITicketDataTimeSlot } from "@pages/product/provider/product.types";
import { IProductAttributes, ITermsAndConditions } from "@pages/productsPreview/provider/productsList.types";
import { IProductDataDto, IMainProductDataDto } from "@pages/cart/provider/cart.types";
import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";
import { IBarcodeState } from "@pages/product/components/ticketData/components/cityPassBarcodePicker/citypassBarcodePicker.types";
import { ISlotStructureLabel } from "@interfaces/rest/viewTickets.dto";

import { ProductUI } from "./productList.ui";
import ProductInventoryUI from "./productInventory.ui";

interface ICartProductsProps {
  ticketData: ITicketData;
  productInventory: ProductInventoryUI;
  product: ProductUI;
}

class CartProductUI {
  public id: string;

  public ticketsTypes: ISelectedTicket[];

  public date: string;

  public isBundle: boolean;

  public name: string;

  public productAttributes: IProductAttributes[];

  public productId: number;

  public productInv?: ITicketDataTimeSlot;

  public productTypeId: number;

  public retailPrice: number;

  public skipVisitDateSelection: boolean | null;

  public slotStructPriceAdjustmentModels: ISlotStructPriceAdjustmentModels[];

  public slotStructureLabels: ISlotStructureLabel[];

  public systemRedeemableOnly: boolean;

  public timedYN: boolean;

  public productInstructions: string[] | null;

  public termsAndConditions: ITermsAndConditions | null;

  public productCategoryName: ProductGroupNameEnum;

  public voucherId: string | null;

  public productPrice: number;

  public isMultiTimed: boolean;

  constructor(data: ICartProductsProps) {
    this.id = uuidv4();
    this.ticketsTypes = this.formTicketTypes(
      data.ticketData.ticketsTypes || [],
      data.productInventory,
      data.ticketData.timeSlot,
    );
    this.date = format(data.ticketData.visitDate || new Date(), "yyyy-MM-dd");
    this.isBundle = data.productInventory.productAndPromotions.isBundle;
    this.name = data.product.name;
    this.productAttributes = data.product.productAttributes;
    this.productId = data.product.productId;
    this.productInv = data.ticketData.timeSlot;
    this.productTypeId = data.product.productTypeId;
    this.retailPrice = data.productInventory.productAndPromotions.retailPrice;
    this.skipVisitDateSelection = data.product.skipVisitDateSelection;
    this.slotStructPriceAdjustmentModels = data.productInventory.productInventoryResult.slotStructPriceAdjustmentModels;
    this.slotStructureLabels = data.productInventory.productInventoryResult.slotStructureLabels;
    this.systemRedeemableOnly = data.product.systemRedeemableOnly;
    this.timedYN = data.productInventory.productAndPromotions.timedYN;
    this.isMultiTimed = data.productInventory.productAndPromotions.isMultiTimed;
    this.productInstructions = data.product.getProductInstructions();
    this.termsAndConditions = data.product.getTermsAndConditions();
    this.productCategoryName = data.product.productCategoryName;
    this.voucherId = data.product.voucherId;
    this.productPrice = data.ticketData.subTotal || 0;
  }

  formCartProductDto(): IProductDataDto[] {
    if (this.productInv) {
      const invIds: number[] = Object.keys(this.productInv).map((id: string) => +id);
      const products: IProductDataDto[] = invIds.reduce((productsDtoArray: IProductDataDto[], invId: number) => {
        const ticks: IProductDataDto[] = this.formProductDataDtoObj(
          this.productInv?.[invId].time?.id,
          this.isMultiTimed ? invId : undefined,
        );

        return [...productsDtoArray, ...ticks];
      }, []);

      return products;
    }

    return this.formProductDataDtoObj();
  }

  private formProductDataDtoObj(productInvId?: number, childProductId?: number): IProductDataDto[] {
    const mainProductData: IMainProductDataDto = {
      productId: this.productId,
      date: this.date,
      externalBarcode: null,
      marketingHyperlinkGuid: null,
      systemRedeemableOnly: this.systemRedeemableOnly,
      productTypeId: this.productTypeId,
    };

    return this.ticketsTypes.reduce((array: IProductDataDto[], obj: ISelectedTicket) => {
      const productDtoObj: IProductDataDto = {
        ...mainProductData,
        productInvId,
        childProductId,
        appliedPromotions:
          obj.id === 0
            ? []
            : [
                {
                  promotionId: obj.id,
                  promotionName: obj.name,
                  code: null,
                },
              ],
        isPromoSale: this.productCategoryName === ProductGroupNameEnum.promoCodeProducts,
      };
      const productDtoObjArray: IProductDataDto[] = Array(obj.selectedCount).fill(productDtoObj);

      return [...array, ...productDtoObjArray];
    }, []);
  }

  formCitypassCartProductDto(barcodes: IBarcodeState[], productInvId?: number): IProductDataDto[] {
    return barcodes.map((voucher: IBarcodeState) => ({
      productId: this.productId,
      productInvId,
      date: this.date,
      externalBarcode: voucher.value,
      marketingHyperlinkGuid: null,
      systemRedeemableOnly: this.systemRedeemableOnly,
      productTypeId: this.productTypeId,
      appliedPromotions: voucher.appliedPromotions || [],
      isPromoSale: false,
    }));
  }

  private formTicketTypes(
    ticketsTypes: ISelectedTicket[],
    productInventory: ProductInventoryUI,
    productInv?: ITicketDataTimeSlot,
  ): ISelectedTicket[] {
    const prodInvIds: number[] = productInv ? Object.keys(productInv).map((id: string) => +id) : [];

    const ticketTypesWithSlotPriceAdjustment: ISelectedTicket[] = ticketsTypes.map((ticketData: ISelectedTicket) => {
      const totalAdjustmentBySlot: number = prodInvIds.reduce((totalDisc: number, id: number) => {
        const adjustmentModelsByPromoId: ISlotStructPriceAdjustmentModels[] =
          productInventory.productInventoryResult.slotStructPriceAdjustmentModels.filter(
            (obj: ISlotStructPriceAdjustmentModels) =>
              obj.promotionId === ticketData.id &&
              productInv?.[id].slotStructureId === obj.slotStructureId &&
              (productInventory.productAndPromotions.isMultiTimed ? obj.childProduct === id : true),
          );

        const adjustmentPrice: number = adjustmentModelsByPromoId.reduce(
          (adj: number, model: ISlotStructPriceAdjustmentModels) => (adj += model.adjustment),
          0,
        );

        return totalDisc + adjustmentPrice;
      }, 0);

      return {
        ...ticketData,
        totalAdjustmentBySlot,
      };
    });

    return ticketTypesWithSlotPriceAdjustment;
  }
}

export default CartProductUI;
