import { IProductDto } from "@pages/productsPreview/provider/productsList.types";
import PromoCodeProductDto from "@interfaces/rest/promoCodeProduct.dto";
import { ProductUI } from "@interfaces/ui/productList.ui";
import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";

const convertPromoProductInstance = (
  promoProducts: PromoCodeProductDto[],
  productCategoryName: ProductGroupNameEnum,
  isHidden?: boolean,
): ProductUI[] => {
  const productsConvertedToSimple: IProductDto[] = promoProducts.map((prod: PromoCodeProductDto) => ({
    productId: prod.productId,
    name: prod.name,
    shortDescription: prod.shortDescription,
    longDescription: prod.longDescription,
    productTypeId: prod.productTypeId,
    imageUrl: prod.imageUrl,
    displayLabel: prod.displayLabel,
    additionalInformation: prod.additionalInformation,
    productUpgrades: prod.productUpgrades,
    productAttributes: prod.productAttributes,
    skipVisitDateSelection: false,
    systemRedeemableOnly: false,
    maxTicketsAllowedPerOrder: prod.maxNbTicketsAllowedPerOrder,
    maxTicketsAllowedPerOrderMsg: prod.maxNbTicketsAllowedPerOrderMsg,
    rollingDaysCount: 0,
    groupSalesAdvPurchaseDayCount: 0,
    groupSalesMinQty: 0,
    groupSalesMinRuleQty: 0,
    groupSalesRewardQty: 0,
    voucherId: null,
    isVoucher: false,
    promotionId: 0,
    emailExchangeButtonLabel: null,
    disclosure: null,
    isPriceMessageEnabled: false,
    priceMessage: null,
  }));

  const products: ProductUI[] = productsConvertedToSimple.map(
    (product: IProductDto) =>
      new ProductUI({
        product,
        fullProductList: productsConvertedToSimple,
        productCategoryName,
        isHidden,
      }),
  );

  return products;
};

export default convertPromoProductInstance;
