import { format } from "date-fns";

import CartProductUI from "@interfaces/ui/cartProduct.ui";
import { TabNameEnum } from "@interfaces/enums/tabName.enum";
import { ITrackingItems } from "@interfaces/types/tracking.types";
import { ISelectedTicket, ITicketDataTime, ITicketDataTimeSlot } from "@pages/product/provider/product.types";
import { IOrderTicketsData } from "@interfaces/rest/orderData.dto";

const getSingleTimeSlotLabel = (productInv: ITicketDataTimeSlot): string =>
  Object.values(productInv)
    .map((item: ITicketDataTime) => {
      const startDate: Date = new Date(item.time?.start as string);
      const endDate: Date = new Date(item.time?.end as string);

      return `${format(startDate, "MM/dd/yyyy HH:mm")} - ${format(endDate, "HH:mm")}`;
    })
    .join(", ");

export const getProductTickets = (product: CartProductUI): ITrackingItems[] => {
  const multiTimedLabel =
    product.productInv &&
    Object.values(product.productInv)
      .map((item: ITicketDataTime) => {
        const startDate: Date = new Date(item.time?.start as string);
        const endDate: Date = new Date(item.time?.end as string);
        const labelData = product.slotStructureLabels.find((label) => label.productId === item.time?.productId);

        return `${labelData?.label} ${format(startDate, "yyyy.dd.MM HH:mm")} - ${format(endDate, "HH:mm")}`;
      })
      .join(", ");

  const singleTimedLabel = product.productInv && getSingleTimeSlotLabel(product.productInv);

  const timedLabel = product.isMultiTimed ? multiTimedLabel : singleTimedLabel;

  return product.ticketsTypes.map((ticket: ISelectedTicket) => ({
    item_name: product.name,
    item_variant: ticket.name,
    price: ticket.price + (ticket.totalAdjustmentBySlot || 0),
    price_base: ticket.price,
    price_adjustment: ticket.totalAdjustmentBySlot || 0,
    quantity: ticket.selectedCount,
    time: product.productInv ? timedLabel : "--:--",
    item_list_id: product.productCategoryName,
    item_list_name: TabNameEnum[product.productCategoryName],
  }));
};

export const getCartTickets = (products: CartProductUI[]): ITrackingItems[] => {
  const trackingItems: ITrackingItems[] = products.map((prod) => getProductTickets(prod)).flat(1);

  return trackingItems;
};

export const getCityPassProductItems = (
  ticketDataResponse: IOrderTicketsData[],
  product: CartProductUI,
): ITrackingItems[] => {
  const items: ITrackingItems[] = ticketDataResponse
    ? ticketDataResponse?.map((ticket: IOrderTicketsData) => ({
        item_name: product.name,
        item_variant: ticket.name,
        time: product.productInv ? getSingleTimeSlotLabel(product.productInv) : "--:--",
        item_list_id: product.productCategoryName,
        item_list_name: TabNameEnum[product.productCategoryName],
      }))
    : [];

  return items;
};
