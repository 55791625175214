import { IOrderProducts } from "@interfaces/rest/selfService.dto";

class SelfServiceOrderData {
  public orderId: number;

  public guestFirstName: string | null;

  public guestLastName: string | null;

  public orderDate: string;

  public visitDate: string;

  public orderConfirmation: string | number;

  public numberOfTickets: number;

  public orderProducts: Array<IOrderProducts>;

  public email: string;

  constructor(data?: SelfServiceOrderData) {
    if (data) {
      this.orderId = data.orderId;
      this.guestFirstName = data.guestFirstName;
      this.guestLastName = data.guestLastName;
      this.orderDate = data.orderDate;
      this.visitDate = data.visitDate;
      this.orderConfirmation = data.orderConfirmation;
      this.numberOfTickets = data.numberOfTickets;
      this.orderProducts = data.orderProducts;
      this.email = data.email;
    }
  }
}

export default SelfServiceOrderData;
