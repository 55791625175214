import { ProductUI } from "@interfaces/ui/productList.ui";
import { IProductsList } from "@pages/productsPreview/provider/productsList.types";

export const getFullProductsList = (productsList: IProductsList): ProductUI[] => {
  const productsCategories: string[] = Object.keys(productsList);

  const allProducts: ProductUI[] = productsCategories.reduce((array: ProductUI[], category: string) => {
    const categoryProds: ProductUI[] | null = productsList[category as keyof IProductsList];
    return categoryProds ? [...array, ...categoryProds] : array;
  }, []);

  return allProducts;
};

export const getProductFromFullListById = (productsList: IProductsList, productId: number): ProductUI | undefined => {
  const allProducts: ProductUI[] = getFullProductsList(productsList);

  return allProducts.find((prod: ProductUI) => prod.productId === productId);
};
