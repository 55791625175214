import { ReactNode } from "react";

import SelfServiceDto from "@interfaces/rest/selfService.dto";

export type ISefleServiceType = ISefleServiceState;

export interface ISefleServiceState {
  isLoading: boolean;
  orderNotFoundError: ReactNode;
  orderData: SelfServiceDto | null;
  getOderData: (orderId: string, email: string) => Promise<void>;
  resetOrderData: VoidFunction;
  resetOrderNotFoundModal: VoidFunction;
  cancelBooking: VoidFunction;
  resetCancellationResult: VoidFunction;
  cancellationResult: ICancellationResult | null;
}

export enum CancellationResultEnum {
  ERROR = "ERROR",
  NOT_REFUNDABLE = "NOT_REFUNDABLE",
}

export interface ICancellationResult {
  isError: boolean;
  message?: CancellationResultEnum;
}

export interface ICancellationError {
  [CancellationResultEnum.ERROR]: ReactNode;
  [CancellationResultEnum.NOT_REFUNDABLE]: ReactNode;
}
