import { useContext } from "react";

import { ISefleServiceType } from "./selfService.types";
import { SelfServiceContext } from "./SelfServiceProvider";

const useSelfService = (): ISefleServiceType => {
  const context = useContext(SelfServiceContext);

  if (context === undefined) {
    throw new Error("useSelfService must be used within a provider");
  }

  return context;
};

export default useSelfService;
