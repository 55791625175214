import React, { FC, createContext, useMemo } from "react";

import useConfig from "@providers/configProvider/useConfig";

import useTrackingState from "./useTrackingState";

const TrackingContext = createContext<any | undefined>(undefined);

const TrackingProvider: FC = ({ children }) => {
  const { config } = useConfig();
  const {
    trackPageView,
    trackAddingToCart,
    trackRemovingFromCart,
    trackPurchase,
    trackViewItemList,
    trackCartView,
    trackCheckoutStart,
    trackPaymentInfoAdd,
    trackCityPassAddToCart,
  } = useTrackingState(config);

  const value = useMemo(
    () => ({
      trackPageView,
      trackAddingToCart,
      trackRemovingFromCart,
      trackPurchase,
      trackViewItemList,
      trackCartView,
      trackCheckoutStart,
      trackPaymentInfoAdd,
      trackCityPassAddToCart,
    }),
    [],
  );

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>;
};

export { TrackingProvider, TrackingContext };
