import { useContext } from "react";

import { CartType } from "./cart.types";
import { CartContext } from "./CartProvider";

const useCart = (): CartType => {
  const context = useContext(CartContext);

  if (context === undefined) {
    throw new Error("useCart must be used within a provider");
  }

  return context;
};

export default useCart;
