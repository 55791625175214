import { useCallback, useState } from "react";
import useAxios from "axios-hooks";

import api from "@api/index";
import CartProductUI from "@interfaces/ui/cartProduct.ui";
import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";
import useConfig from "@providers/configProvider/useConfig";
import { ConfigClientEnum, IMemberVenueId, ISalesTerminalTypeId } from "@interfaces/enums/apiData.enum";
import IOrderDataDto from "@interfaces/rest/orderData.dto";
import { ICheckoutFormData } from "@pages/checkout/components/CheckoutForm/checkoutForm.types";
import ConfirmOrderPurchaseDto from "@interfaces/rest/confirmOrderPurchase.dto";
import { IConfirmOrderPromoData } from "@pages/product/provider/product.types";
import ConfirmedOrderPromoDto from "@interfaces/rest/confirmedOrderPromo";
import useTracking from "@providers/trackingProvider/useTracking";

import { ICartState, IProductDataDto, IOrderPayloadDto } from "./cart.types";

const useCartState = (): ICartState => {
  const { config } = useConfig();
  const { trackPurchase, trackAddingToCart, trackRemovingFromCart } = useTracking();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<CartProductUI[] | null>(null);
  const [isGroupSales, setIsGroupSales] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<IOrderDataDto | null>(null);
  const [isOrderError, setIsOrderError] = useState<boolean>(false);

  const [{}, postOrderData] = useAxios<IOrderDataDto>(
    {
      url: api.createPendingOrder,
      method: "post",
    },
    { manual: true },
  );

  const [{}, postConfirmPurchase] = useAxios<ConfirmOrderPurchaseDto>(
    {
      method: "post",
    },
    { manual: true },
  );

  const [{}, postConfirmOrderPromo] = useAxios<ConfirmedOrderPromoDto>(
    {
      method: "post",
    },
    { manual: true },
  );

  const createOrder = useCallback(
    async (
      productsDto: IProductDataDto[],
      productCategoryName: ProductGroupNameEnum,
      productId: number,
    ): Promise<boolean> => {
      try {
        setIsLoading(true);
        const orderPayload: IOrderPayloadDto = {
          eventId: productCategoryName === ProductGroupNameEnum.productsEvents ? productId : null,
          userAccountId: 1137,
          memberVenueId: IMemberVenueId[config?.client || ConfigClientEnum.sn],
          salesTerminalTypeId: ISalesTerminalTypeId[config?.client || ConfigClientEnum.sn],
          orderType: productCategoryName === ProductGroupNameEnum.productsGroups ? "GroupSale" : "GeneralSale",
          groupSaleIndicator: productCategoryName === ProductGroupNameEnum.productsGroups ? 1 : 0,
          products: productsDto,
        };

        const response = await postOrderData({
          data: orderPayload,
        });
        setOrderData(response.data);
        setIsLoading(false);
        return true;
      } catch (error) {
        setIsOrderError(true);
        setIsLoading(false);
        return false;
      }
    },
    [],
  );

  const addProduct = useCallback(
    async (newProduct: CartProductUI, category: ProductGroupNameEnum): Promise<boolean> => {
      try {
        const productDto: IProductDataDto[] = newProduct.formCartProductDto();
        const productsListDto: IProductDataDto[] = products?.length
          ? products.reduce(
              (array: IProductDataDto[], product: CartProductUI) => [...array, ...product.formCartProductDto()],
              [],
            )
          : [];

        const isOrderCreated: boolean = await createOrder(
          [...productDto, ...productsListDto],
          newProduct.productCategoryName,
          newProduct.productId,
        );

        if (!isOrderCreated) {
          return false;
        }

        trackAddingToCart(newProduct);
        setProducts(products ? [...products, newProduct] : [newProduct]);
        setIsGroupSales(category === ProductGroupNameEnum.productsGroups);
        return true;
      } catch (error) {
        return false;
      }
    },
    [products],
  );

  const handleCartErase = useCallback(() => {
    setProducts(null);
    setOrderData(null);
    setIsGroupSales(false);
  }, []);

  const removeProduct = useCallback(
    async (id: string): Promise<boolean> => {
      try {
        if (products) {
          setIsLoading(true);
          const newProducts: CartProductUI[] = products.filter((product: CartProductUI) => product.id !== id);
          const productsListDto: IProductDataDto[] = newProducts?.length
            ? newProducts.reduce(
                (array: IProductDataDto[], product: CartProductUI) => [...array, ...product.formCartProductDto()],
                [],
              )
            : [];

          if (!productsListDto.length) {
            handleCartErase();
            setIsLoading(false);
            return true;
          }

          const isOrderCreated: boolean = await createOrder(
            productsListDto,
            newProducts[0].productCategoryName,
            newProducts[0].productId,
          );

          if (!isOrderCreated) {
            setIsLoading(false);
            return false;
          }

          const removedProduct = products.find((item) => item.id === id);

          if (removedProduct) {
            trackRemovingFromCart(removedProduct);
          }

          setProducts(newProducts);
          setIsLoading(false);
          return true;
        }
        return false;
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        return false;
      }
    },
    [products],
  );

  const updateCartProduct = useCallback(
    async (id: string, newProductEntity: CartProductUI): Promise<boolean> => {
      if (!products) {
        return false;
      }

      try {
        const productsWithoutOld: CartProductUI[] = products.filter((prod: CartProductUI) => prod.id !== id);
        const productsListDto: IProductDataDto[] = productsWithoutOld.reduce(
          (array: IProductDataDto[], product: CartProductUI) => [...array, ...product.formCartProductDto()],
          [],
        );

        const isOrderCreated: boolean = await createOrder(
          [...productsListDto, ...newProductEntity.formCartProductDto()],
          newProductEntity.productCategoryName,
          newProductEntity.productId,
        );

        if (!isOrderCreated) {
          return false;
        }

        setProducts([...productsWithoutOld, newProductEntity]);
        return true;
      } catch (error) {
        setIsOrderError(true);
        return false;
      }
    },
    [products],
  );

  const handleErrorClean = useCallback(() => {
    setIsOrderError(false);
  }, []);

  const handlePurchase = useCallback(
    async (
      formData: ICheckoutFormData,
      handleSoldOutProducts: (product: CartProductUI[]) => void,
    ): Promise<ConfirmOrderPurchaseDto | null> => {
      if (!orderData?.orderId || !products) {
        return null;
      }

      try {
        setIsLoading(true);

        const response = await postConfirmPurchase({
          url: api.completePurchase(orderData.orderId),
          transformRequest: (data: any, headers: any) => {
            headers.carttoken = config?.cartToken || "";
            headers["Content-Type"] = "application/json";

            data = {
              email: formData.email.value,
              firstName: formData.firstName.value,
              lastName: formData.lastName.value,
              address1: formData.address1.value,
              address2: formData.address2.value,
              city: formData.city.value,
              state: formData.state.value,
              postalCode: formData.postalCode.value,
              country: formData.country.value,
              phoneNumber: formData.phoneNumber.value,
              cardOwnerName: formData.cardOwnerName.value,
              cardNumber: formData.cardNumber.value,
              cardMonthExp: formData.cardMonthExp.value,
              cardYearExp: formData.cardYearExp.value,
              cardCVV: formData.cardCVV.value,
              sendNotificationYN: formData.sendNotificationYN.value,
              userAccountId: 1137,
            };

            return JSON.stringify(data);
          },
        });

        if (response.data?.successfulYN) {
          trackPurchase(orderData, products);
        }

        return response.data;
      } catch (error: any) {
        if (error.response?.data?.Code === 21) {
          const filteredSoldOutProducts =
            products?.filter((product) =>
              product.slotStructureLabels.filter((item) => error.response.data?.Detail.includes(item.productId)),
            ) || [];

          handleSoldOutProducts(filteredSoldOutProducts);
        }
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [orderData, products],
  );

  const handleConfirmOrderPromo = useCallback(
    async (data: IConfirmOrderPromoData, orderId: number): Promise<boolean> => {
      try {
        setIsLoading(true);
        const response = await postConfirmOrderPromo({
          url: api.confirmOrderPromo(orderId),
          data,
        });

        if (response.status === 200) {
          setIsLoading(false);
          return true;
        }

        setIsLoading(false);
        return true;
      } catch (error) {
        setIsLoading(false);
        return false;
      }
    },
    [],
  );

  return {
    isLoading,
    addProduct,
    products,
    isGroupSales,
    removeProduct,
    orderData,
    isOrderError,
    handleErrorClean,
    updateCartProduct,
    handleCartErase,
    handlePurchase,
    handleConfirmOrderPromo,
  };
};

export default useCartState;
