import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import useAxios from "axios-hooks";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";

import api from "@api/index";
import { IProductDto, IProductsList } from "@pages/productsPreview/provider/productsList.types";
import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";
import ProductInventoryDto from "@interfaces/rest/productInventory.dto";
import ProductInventoryUI from "@interfaces/ui/productInventory.ui";
import { ProductUI } from "@interfaces/ui/productList.ui";
import recalculateTicketTypes from "@utils/recalculateTicketTypes";
import CartProductUI from "@interfaces/ui/cartProduct.ui";
import CityPassVoucherDto from "@interfaces/rest/cityPassVoucher.dto";
import IOrderDataDto from "@interfaces/rest/orderData.dto";
import { IOrderPayloadDto, IProductDataDto } from "@pages/cart/provider/cart.types";
import useConfig from "@providers/configProvider/useConfig";
import { ConfigClientEnum, IMemberVenueId, ISalesTerminalTypeId } from "@interfaces/enums/apiData.enum";
import { calculateSubTotalByTicketTypes, getSubTotalFromInventory } from "@utils/calculateSubTotal";
import useTracking from "@providers/trackingProvider/useTracking";
import { getProductFromFullListById } from "@utils/getProductInstance";
import { IInvTimeOfDayPrices } from "@interfaces/types/productInventory.type";

import {
  IProductState,
  ISelectedTicket,
  ITicketData,
  TicketStepsEnum,
  ILocationState,
  IFetchCitypassProductResult,
  cityPassC3NameRegExp,
} from "./product.types";
import { IBarcodeState } from "../components/ticketData/components/cityPassBarcodePicker/citypassBarcodePicker.types";

const useProductState = (
  productList: IProductsList | null,
  cartProducts: CartProductUI[] | null,
  handleDirectProductPageOpen: (id: string) => Promise<void>,
): IProductState => {
  const { trackPageView, trackCityPassAddToCart } = useTracking();
  const { config } = useConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductUI | null>(null);
  const [cityPassProduct, setCityPassProduct] = useState<ProductUI | null>(null);
  const [isCityPassExchange, setIsCityPassExchange] = useState<boolean>(false);
  const [productDates, setProductDates] = useState<string[] | null>(null);
  const [productInventory, setProductInventory] = useState<ProductInventoryUI | null>(null);
  const [ticketData, setTicketData] = useState<ITicketData | null>(null);
  const [step, setStep] = useState<number>(1);
  const [barcodes, setBarcodes] = useState<IBarcodeState[] | null>(null);
  const [isExchangeOrdering, setIsExchangeOrdering] = useState<boolean>(false);
  const [exchangeOrderData, setExchangeOrderData] = useState<IOrderDataDto | null>(null);

  const [{}, getProductsDates] = useAxios<string[]>(
    {
      url: api.productDates,
      method: "post",
    },
    { manual: true },
  );

  const [{}, getProductInventory] = useAxios<ProductInventoryDto>(
    {
      method: "post",
    },
    { manual: true },
  );

  const [{}, getCitypassProduct] = useAxios<CityPassVoucherDto[]>(
    {
      url: api.cityPassVoucher,
      method: "post",
    },
    { manual: true },
  );

  const [{}, postOrderData] = useAxios<IOrderDataDto>(
    {
      url: api.createPendingOrder,
      method: "post",
    },
    { manual: true },
  );

  useEffect(() => {
    setIsCityPassExchange(!!product?.voucherId);
  }, [product?.voucherId]);

  const getProductFromList = useCallback(
    (id: string, type: ProductGroupNameEnum, voucherId: string | null) => {
      const productItem: ProductUI | undefined = productList?.[type]?.find(
        (prod: ProductUI) => prod.productId === +id && voucherId === prod.voucherId,
      );
      if (productItem) {
        setProduct(productItem);
      }
    },
    [productList],
  );

  const fetchProductDates = async (prodType: ProductGroupNameEnum, cartProduct?: CartProductUI): Promise<void> => {
    if (!product && !cartProduct) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await getProductsDates({
        data: {
          productId: product?.productId || cartProduct?.productId,
          isGroupSales: prodType === ProductGroupNameEnum.productsGroups,
          voucherId: product?.voucherId || cartProduct?.voucherId,
          minQuantityAvailableToCheck: 1, // it's for citypass tickets count
        },
      });
      if (!Array.isArray(response.data)) {
        return;
      }
      setProductDates(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchProductInventory = async (selectedVisitDate: Date): Promise<void> => {
    if (!ticketData?.visitDate || !product) {
      return;
    }

    try {
      setIsLoading(true);
      const dateFormatted: string = format(selectedVisitDate, "yyyy-MM-dd");
      const response = await getProductInventory({
        url: api.productInventory(product?.productId),
        data: {
          startDate: `${dateFormatted}T00:00:00Z`,
          endDate: `${dateFormatted}T23:59:59Z`,
          isGroupSales: product.productCategoryName === ProductGroupNameEnum.productsGroups,
          voucherId: null,
        },
      });

      trackPageView(product, response.data.productAndPromotions.retailPrice);
      setProductInventory(new ProductInventoryUI(response.data));
      setIsLoading(false);
    } catch (error) {
      console.log("error while fetched and processed prod inventory", error);
      setIsLoading(false);
    }
  };

  const handleAddTicketData = useCallback((newData: ITicketData, newStep?: TicketStepsEnum): void => {
    setTicketData({ ...newData });
    if (newStep) {
      setStep(newStep);
    }
  }, []);

  const updateTicketData = useCallback(
    async (newProductId: string): Promise<void> => {
      try {
        const state = location.state as ILocationState;

        if (state?.editCartId) {
          const productEntity: CartProductUI | undefined = cartProducts?.find(
            (prod: CartProductUI) => prod.id === state.editCartId,
          );

          if (!productEntity) {
            navigate(location.pathname + location.search, { state: {} });
            return;
          }

          const newVisitDate = new Date(`${productEntity.date}T00:00:00`);

          fetchProductDates(productEntity.productCategoryName, productEntity);
          fetchProductInventory(newVisitDate);

          const newTicketData: ITicketData = {
            visitDate: newVisitDate,
            ticketsTypes: productEntity.ticketsTypes,
            timeSlot: productEntity?.productInv,
            subTotal: productEntity.productPrice,
          };
          setTicketData(newTicketData);
          location.state = {
            ...location.state,
            ticketDataDefault: newTicketData,
          };
          setStep(TicketStepsEnum.ENTRY_TIME);
          return;
        }

        if (!ticketData?.visitDate || !ticketData.ticketsTypes || !product?.productId) {
          return;
        }

        // firstly getting new product dates
        setIsLoading(true);
        const newProductDates = await getProductsDates({
          data: {
            productId: +newProductId,
            isGroupSales: product?.productCategoryName === ProductGroupNameEnum.productsGroups,
            voucherId: product.voucherId,
            minQuantityAvailableToCheck: 1, // it's for citypass ticket count
          },
        });

        if (!newProductDates.data.includes(format(ticketData.visitDate, "yyyy-MM-dd"))) {
          // to FIX need to add error set here!!!
          console.log("dates is absent at list!!!");
          return;
        }

        const dateFormatted: string = format(ticketData.visitDate, "yyyy-MM-dd");
        const newProductInventory = await getProductInventory({
          url: api.productInventory(newProductId),
          data: {
            startDate: `${dateFormatted}T00:00:00Z`,
            endDate: `${dateFormatted}T23:59:59Z`,
            isGroupSales: product.productCategoryName === ProductGroupNameEnum.productsGroups,
            voucherId: product.voucherId,
          },
        });

        const newTicketTypes: ISelectedTicket[] = recalculateTicketTypes(
          ticketData.ticketsTypes,
          newProductInventory.data.productAndPromotions.promotions,
          newProductInventory.data.productAndPromotions.retailPrice,
        );

        const newProductInventoryUI: ProductInventoryUI = new ProductInventoryUI(newProductInventory.data);

        const timeOfDayPriceList: IInvTimeOfDayPrices =
          newProductInventoryUI?.getTimeOfDayProductsPricesList(newTicketTypes);

        const newTicketData: ITicketData = {
          visitDate: ticketData.visitDate,
          ticketsTypes: newTicketTypes,
          subTotal:
            !newProductInventory.data.productAndPromotions.timedYN || !ticketData.timeSlot
              ? calculateSubTotalByTicketTypes(newTicketTypes)
              : getSubTotalFromInventory(ticketData.timeSlot, timeOfDayPriceList),
        };

        setStep(
          newProductInventory.data.productAndPromotions.timedYN ? TicketStepsEnum.TICKETS : TicketStepsEnum.ENTRY_TIME,
        );

        setTicketData(newTicketData);
        setProductDates(newProductDates.data);
        setProductInventory(newProductInventoryUI);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    },
    [product, ticketData, location, cartProducts],
  );

  const addCitypassBarcodeInstance = useCallback((): void => {
    const DEFAULT_BARCODE: IBarcodeState = {
      id: uuidv4(),
      value: "",
      verified: false,
      error: "",
    };

    setBarcodes(barcodes ? [...barcodes, DEFAULT_BARCODE] : [DEFAULT_BARCODE]);
  }, [barcodes]);

  const fetchCitypassVoucherProduct = useCallback(
    async (barcode: string): Promise<IFetchCitypassProductResult> => {
      if (!productList || !product) {
        return {
          isValid: false,
        };
      }

      try {
        setIsLoading(true);
        const response = await getCitypassProduct({
          data: {
            voucherCode: barcode,
            barcodeProvider: cityPassC3NameRegExp.test(product.name) ? "CityPassC3" : "CityPass",
          },
        });
        const productItem: ProductUI | undefined = productList[ProductGroupNameEnum.products]?.find(
          (prod: ProductUI) => prod.productId === response.data[0]?.productId,
        );

        if (productItem && barcodes) {
          const currentProductBackToDto: IProductDto = productItem.formProductBackToDto();
          const newProductItem: IProductDto = {
            ...currentProductBackToDto,
            ...productItem,
            productUpgrades: currentProductBackToDto.productUpgrades,
            appliedPromotions: response.data[0].appliedPromotions,
          };
          const productsListBackToDto: IProductDto[] = productList.products.map((prod) => prod.formProductBackToDto());
          const newProduct: ProductUI = new ProductUI({
            product: newProductItem,
            productCategoryName: ProductGroupNameEnum.productsOtherAttractions,
            fullProductList: productsListBackToDto,
          });
          setCityPassProduct(newProduct);

          const barcodesCount: number = barcodes.filter((obj: IBarcodeState) => obj.verified).length;

          const cityPassProductDates = await getProductsDates({
            data: {
              productId: newProduct?.productId,
              isGroupSales: false,
              voucherId: newProduct.voucherId,
              minQuantityAvailableToCheck: barcodesCount + 1,
            },
          });

          setProductDates(cityPassProductDates.data);

          setStep(TicketStepsEnum.VISIT_DATE);
        }

        setIsLoading(false);
        return {
          isValid: true,
          appliedPromotions: response.data[0].appliedPromotions,
        };
      } catch (error) {
        setIsLoading(false);
        return {
          isValid: false,
        };
      }
    },
    [productList, product, barcodes],
  );

  const handleCitypassBarcodeChange = useCallback(
    async (id: string, value: string): Promise<void> => {
      const obj: IBarcodeState | undefined = barcodes?.find((barcodeObj: IBarcodeState) => barcodeObj.id === id);
      const sameValuedObject: IBarcodeState | undefined = barcodes?.find(
        (barcodeObj: IBarcodeState) => barcodeObj.value === value && barcodeObj.id !== id,
      );

      if (barcodes && obj && sameValuedObject?.value) {
        obj.error = "Ticket already entered";
        setBarcodes([...barcodes]);
        return;
      }

      if (barcodes && obj && value) {
        const fetchResult: IFetchCitypassProductResult = await fetchCitypassVoucherProduct(value);
        obj.value = value;
        obj.verified = fetchResult.isValid;
        obj.appliedPromotions = fetchResult?.appliedPromotions;
        obj.error = fetchResult.isValid ? "" : "Invalid barcode";

        setBarcodes([...barcodes]);
      }
    },
    [barcodes, cityPassProduct],
  );

  useEffect(() => {
    const id: string | null = searchParams.get("productId");
    const type: string | null = searchParams.get("type");
    const voucherId: string | null = searchParams.get("voucherId");
    const directAccess: string | null = searchParams.get("directAccess");

    if (directAccess && productList && id) {
      const presentProduct: ProductUI | undefined = getProductFromFullListById(productList, +id);

      if (!presentProduct) {
        handleDirectProductPageOpen(directAccess);
      }
    }

    if (!id || !type) {
      return navigate("/");
    }

    getProductFromList(id, type as ProductGroupNameEnum, voucherId);
    updateTicketData(id);
  }, [searchParams, productList]);

  useEffect(() => {
    if (ticketData?.visitDate) {
      fetchProductInventory(ticketData?.visitDate);
    }
  }, [ticketData?.visitDate]);

  useEffect(() => {
    if (productInventory?.productAndPromotions.isMultiTimed && ticketData?.ticketsTypes && ticketData.timeSlot) {
      const generalPrice: number = calculateSubTotalByTicketTypes(ticketData.ticketsTypes) || 0;
      const discount: number = productInventory.getMultiTimedDiscount(ticketData.ticketsTypes, ticketData.timeSlot);
      setTicketData({
        ...ticketData,
        subTotal: generalPrice + discount,
      });
    }
  }, [ticketData?.ticketsTypes, ticketData?.timeSlot]);

  const ticketTotal: number = useMemo(() => {
    const barcodesCount: number = barcodes ? barcodes.filter((obj) => obj.verified).length : 0;
    const countByTicket: number = ticketData?.ticketsTypes
      ? ticketData.ticketsTypes.reduce((total: number, obj: ISelectedTicket) => total + obj.selectedCount, 0)
      : 0;

    return countByTicket + barcodesCount;
  }, [ticketData?.ticketsTypes, barcodes]);

  const handleExchangeOrder = useCallback(async () => {
    if (!product || !ticketData || !productInventory) {
      return;
    }

    try {
      setIsLoading(true);
      const productToCart: CartProductUI = new CartProductUI({ ticketData, product, productInventory });
      const voucherArray: IBarcodeState[] | undefined = barcodes?.filter((obj: IBarcodeState) => obj.verified);
      if (!voucherArray?.length) {
        return;
      }

      const timeSlotIds: number[] = ticketData.timeSlot
        ? Object.keys(ticketData.timeSlot).map((id: string) => +id)
        : [];
      const productInvId: number | undefined = timeSlotIds.length && ticketData.timeSlot?.[timeSlotIds[0]].time?.id;

      const productDto: IProductDataDto[] = productToCart.formCitypassCartProductDto(voucherArray, productInvId);

      const orderPayload: IOrderPayloadDto = {
        eventId: null,
        userAccountId: 1137,
        memberVenueId: IMemberVenueId[config?.client || ConfigClientEnum.sn],
        salesTerminalTypeId: ISalesTerminalTypeId[config?.client || ConfigClientEnum.sn],
        orderType: "GeneralSale",
        groupSaleIndicator: 0,
        products: productDto,
      };

      const response = await postOrderData({
        data: orderPayload,
      });
      setExchangeOrderData(response.data);
      setIsExchangeOrdering(true);
      setIsLoading(false);
      trackCityPassAddToCart(response.data, productToCart);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [product, productInventory, ticketData, cityPassProduct]);

  const removeCitypassBarcode = useCallback(
    (id: string) => {
      if (barcodes) {
        const newBarcodes = barcodes.filter((obj: IBarcodeState) => obj.id !== id);
        setBarcodes(
          newBarcodes.length
            ? newBarcodes
            : [
                {
                  id: uuidv4(),
                  value: "",
                  verified: false,
                  error: "",
                },
              ],
        );

        if (!newBarcodes.length) {
          setCityPassProduct(null);
          setTicketData(null);
          setStep(1);
        }
      }
    },
    [barcodes],
  );

  return {
    isLoading,
    product,
    productDates,
    fetchProductDates,
    ticketData,
    productInventory,
    step,
    handleAddTicketData,
    ticketTotal,
    isCityPassExchange,
    barcodes,
    addCitypassBarcodeInstance,
    handleCitypassBarcodeChange,
    cityPassProduct,
    isExchangeOrdering,
    handleExchangeOrder,
    removeCitypassBarcode,
    exchangeOrderData,
  };
};

export default useProductState;
