import { useContext } from "react";

import { ITrackingType } from "./tracking.types";
import { TrackingContext } from "./TrackingProvider";

const useTracking = (): ITrackingType => {
  const context = useContext(TrackingContext);

  if (context === undefined) {
    throw new Error("useTracking must be used within a provider");
  }

  return context;
};

export default useTracking;
