import {
  IProductInventoryModels,
  IPromoAdjustments,
  ISlotStructPriceAdjustmentModels,
} from "@interfaces/types/productInventory.type";

interface ISlotIdsByPoduct {
  [key: number]: number[];
}

const getMultiTimedProductPromotionAdjustment = (
  productInventoryModels: IProductInventoryModels[],
  slotStructPriceAdjustmentModels: ISlotStructPriceAdjustmentModels[],
  promoId: number,
): IPromoAdjustments => {
  const slotStructPriceAdjustmentModelsFiltered: ISlotStructPriceAdjustmentModels[] =
    slotStructPriceAdjustmentModels.filter((adjModel: ISlotStructPriceAdjustmentModels) =>
      productInventoryModels.some(
        (invModel: IProductInventoryModels) => invModel.slotStructureId === adjModel.slotStructureId,
      ),
    );

  const productIdsList: number[] = productInventoryModels
    .map((item: IProductInventoryModels) => item.productId)
    .filter((value: number, index: number, array: number[]) => array.indexOf(value) === index);

  const slotIdsByProduct: ISlotIdsByPoduct = productIdsList.reduce((obj: ISlotIdsByPoduct, productId: number) => {
    const modelsByProductId: IProductInventoryModels[] = productInventoryModels.filter(
      (model: IProductInventoryModels) => model.productId === productId,
    );

    const slotStructureIds: number[] = modelsByProductId
      .map((item: IProductInventoryModels) => item.slotStructureId)
      .filter((value: number, index: number, array: number[]) => array.indexOf(value) === index);

    return {
      ...obj,
      [productId]: slotStructureIds,
    };
  }, {});

  const adjustmentsByProductId: IPromoAdjustments[] = productIdsList.reduce(
    (obj: IPromoAdjustments[], productId: number) => {
      const slotIds: number[] = slotIdsByProduct[productId];
      const adjustments = slotStructPriceAdjustmentModelsFiltered.filter(
        (model: ISlotStructPriceAdjustmentModels) => model.childProduct === productId && model.promotionId === promoId,
      );

      const adjValues: number[] = slotIds.map((slot: number) => {
        const adjForSlotArray: ISlotStructPriceAdjustmentModels[] = adjustments.filter(
          (adjObj: ISlotStructPriceAdjustmentModels) => adjObj.slotStructureId === slot,
        );
        const adjForSlotValue = adjForSlotArray.reduce(
          (acc: number, slotModel: ISlotStructPriceAdjustmentModels) => acc + slotModel.adjustment,
          0,
        );

        return adjForSlotValue;
      });

      const minAdjValue = Math.min(...adjValues);
      const maxAdjValue = Math.max(...adjValues);

      return [
        ...obj,
        {
          minAdjValue,
          maxAdjValue,
        },
      ];
    },
    [],
  );

  const totalAdjustments: IPromoAdjustments = adjustmentsByProductId.reduce(
    (totalAdj: IPromoAdjustments, adj: any) => ({
      minAdjValue: totalAdj.minAdjValue + adj.minAdjValue,
      maxAdjValue: totalAdj.maxAdjValue + adj.maxAdjValue,
    }),
    { minAdjValue: 0, maxAdjValue: 0 },
  );

  return totalAdjustments;
};

export default getMultiTimedProductPromotionAdjustment;
