import React, { FC, createContext, useMemo } from "react";

import useConfigState from "./useConfigState";

const ConfigContext = createContext<any | undefined>(undefined);

const ConfigProvider: FC = ({ children }) => {
  const { config, script } = useConfigState();

  const value = useMemo(
    () => ({
      config,
      script,
    }),
    [config, script],
  );

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export { ConfigProvider, ConfigContext };
