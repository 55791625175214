import { IPromotionsDto } from "@interfaces/types/productInventory.type";
import { ISelectedTicket } from "@pages/product/provider/product.types";

const recalculateTicketTypes = (
  ticketTypes: ISelectedTicket[],
  promotions: IPromotionsDto[],
  newPrice: number,
): ISelectedTicket[] =>
  ticketTypes.reduce((array: ISelectedTicket[], ticket: ISelectedTicket) => {
    const objectInPromotions: IPromotionsDto | undefined = promotions.find(
      (promo: IPromotionsDto) => promo.id === ticket.id,
    );

    if (objectInPromotions) {
      return [
        ...array,
        {
          ...ticket,
          price: newPrice + objectInPromotions.value,
          value: objectInPromotions.value,
        },
      ];
    }

    const newObj: ISelectedTicket = {
      id: 0,
      name: "Regular",
      price: newPrice,
      selectedCount: ticket.selectedCount,
      type: "",
      unit: "",
      value: 0,
    };

    const regularSelectedTicket: ISelectedTicket | undefined = array.find((obj: ISelectedTicket) => obj.id === 0);

    if (!regularSelectedTicket) {
      return [...array, newObj];
    }

    regularSelectedTicket.selectedCount += newObj.selectedCount;
    return array;
  }, []);

export default recalculateTicketTypes;
