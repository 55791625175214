import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";
import { ProductUI } from "@interfaces/ui/productList.ui";
import { IAppliedPromotionsCitypass } from "@pages/cart/provider/cart.types";

export type ProductsListType = IProductsListState;

export interface IProductUpgrades {
  productId: number;
  headline: string;
  upgradeProductId: number;
  relationshipEnumId: number;
  relationshipEnum: string;
  promotionalMessage: string;
  longDescription: string;
  imageUrl: string;
  shortDescription: string;
  skipVisitDateSelection: boolean | null;
  systemRedeemableOnly: boolean;
}

export interface IProductUpgradesFiltered {
  id: number;
  name: string;
  headline: string;
  title: string;
  longDescription: string;
  imageUrl: string;
}

export interface ITermsAndConditions {
  label: string;
  link: string;
}

export interface IProductAttributes {
  productAttributeId: number;
  productId: number;
  attributeName: string;
  attributeValue: string | null;
  attributeRequired: boolean;
  attributeValueSetByUI: boolean;
}

export interface IProductDto {
  appliedPromotions?: IAppliedPromotionsCitypass[] | null;
  productId: number;
  name: string;
  shortDescription: string;
  longDescription: string;
  productTypeId: number;
  imageUrl: string;
  displayLabel: string | null;
  additionalInformation: string;
  productUpgrades: IProductUpgrades[] | null;
  productAttributes: IProductAttributes[];
  skipVisitDateSelection: boolean | null;
  systemRedeemableOnly: boolean;
  maxTicketsAllowedPerOrder: number | null;
  maxTicketsAllowedPerOrderMsg: string | null;
  rollingDaysCount: number;
  groupSalesAdvPurchaseDayCount: number;
  groupSalesMinQty: number;
  groupSalesMinRuleQty: number;
  groupSalesRewardQty: number;
  voucherId: string | null;
  isVoucher: boolean;
  promotionId: number;
  emailExchangeButtonLabel: string | null;
  disclosure: string | null;
  priceMessage: string | null;
  isPriceMessageEnabled: boolean;
}

export interface IProduct extends Omit<IProductDto, "productUpgrades"> {
  productUpgrades: IProductUpgradesFiltered[] | null;
  productCategoryName: ProductGroupNameEnum;
}

export interface IProductsList {
  products: ProductUI[];
  productsOtherAttractions: ProductUI[];
  productsEvents: ProductUI[];
  productsGroups: ProductUI[];
  promoCodeProducts: ProductUI[] | null;
}

export interface ICheckoutData {
  pricingNotice: string;
  checkOutThankMessage: string;
  checkOutUpgradeMessage: string;
  checkOutMessage: string;
}

export interface IProductsListState {
  isLoading: boolean;
  isPromoInvalid: boolean;
  productsList: IProductsList | null;
  checkoutData: ICheckoutData | null;
  getProductsByPromo: (promoCode: string, promoCodeMode: PromoCodeModeEnum) => void;
  resetPromoError: VoidFunction;
  calculatePruductCategoryById: (productId: number) => ProductGroupNameEnum | null;
  handleDirectProductPageOpen: (id: string) => Promise<void>;
}

export enum PromoCodeModeEnum {
  ProductSalesChannelJunction = "1",
  ProductPromoHyperlink = "2",
}
