import { IMemberVenueId } from "@interfaces/enums/apiData.enum";
import { PromoCodeModeEnum } from "@pages/productsPreview/provider/productsList.types";

export default {
  config: "/config.json",
  getTrackingScript: (memberVenueId: IMemberVenueId): string => `/api/memberVenue/html/${memberVenueId}`,
  productsWithUpgrades: "/api/web-sales/products-with-upgrades",
  productDates: "/api/web-sales/product-dates",
  productInventory: (productId: string | number): string => `/api/web-sales/${productId}/promotions-inventory`,
  productsByPromocode: (promoCode: string, promoCodeMode: PromoCodeModeEnum): string =>
    `/api/web-sales/products-with-upgrades-by-promocode/${promoCode}/${promoCodeMode}`,
  createPendingOrder: "/api/web-sales/create-pending-order",
  orderData: (orderId: string, email: string): string => `/api/web-sales/order/search/${orderId}/${email}`,
  orderRefund: "/api/web-sales/order/refund",
  cityPassVoucher: "/api/web-sales/cityPassVoucher",
  confirmOrderPromo: (orderId: number): string => `/api/web-sales/confirm-order-promo/${orderId}`,
  completePurchase: (orderId: number): string => `/api/web-sales/confirm-purchase/${orderId}`,
  getViewTickets: "/api/web-sales/view-tickets",
  getProductByHyperLink: (guid: string): string => `/api/web-sales/product-hyperlink/${guid}`,
};
