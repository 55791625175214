import React, { FC, useCallback, useEffect, useState } from "react";
import { format } from "date-fns";

import useSelfService from "@pages/selfService/provider/useSelfService";
import { IOrderProducts } from "@interfaces/rest/selfService.dto";
import useConfig from "@providers/configProvider/useConfig";
import { CancellationResultEnum, ICancellationError } from "@pages/selfService/provider/selfService.types";
import { venueEmails } from "@utils/getSSTCEmail";
import { ConfigClientEnum } from "@interfaces/enums/apiData.enum";

import { IErrorProps, IOrderDataProps } from "./orderData.types";
import "./orderData.scss";

const RequestError: FC<IErrorProps> = ({ message }) => (
  <>
    <div>Your request cannot be processed at this time.</div>

    <div>
      Please try again later or contact our Guest Services department at (206) 905-2100 or{" "}
      <a href={message.href} className="order-data__link">
        {message.address}
      </a>{" "}
      for further assistance.
    </div>
  </>
);

const NotRefundableError: FC<IErrorProps> = ({ message }) => (
  <>
    <div>Your order is not eligible for an online cancellation.</div>

    <div>
      Please contact our Guest Services department at (206) 905-2100 or{" "}
      <a href={message.href} className="order-data__link">
        {message.address}
      </a>{" "}
      for further assistance.
    </div>
  </>
);

export const getVenueError = (client: ConfigClientEnum): ICancellationError => ({
  [CancellationResultEnum.ERROR]: <RequestError message={venueEmails[client]} />,
  [CancellationResultEnum.NOT_REFUNDABLE]: <NotRefundableError message={venueEmails[client]} />,
});

const OrderData: FC<IOrderDataProps> = ({ handleFinish }) => {
  const { config } = useConfig();
  const { orderData, resetOrderData, cancelBooking, cancellationResult, resetCancellationResult } = useSelfService();
  const [isTermsAgreed, setIsTermsAgreed] = useState<boolean>(false);

  useEffect(() => (): void => resetCancellationResult(), []);

  const handleTermsClick = useCallback(() => {
    setIsTermsAgreed(!isTermsAgreed);
  }, [isTermsAgreed]);

  const cancelBookingHandler = useCallback(() => {
    cancelBooking();
  }, []);

  return (
    <div className="order-data__wrapper">
      {cancellationResult && !cancellationResult.isError ? (
        <div className="order-data__success">
          <div>
            Order <span className="order-data__order-id">{orderData?.orderId}</span> has been canceled.
          </div>
          <div>You should expect to receive your refund within 5-10 business days.</div>
          <button onClick={handleFinish} className="order-data__close">
            DONE
          </button>
        </div>
      ) : (
        <>
          <div className="order-data__title-wrapper">
            {!cancellationResult?.isError ? (
              <div>To request a refund of your ticket order, please check the box below and click "CANCEL TICKETS"</div>
            ) : (
              <div className="order-data__error">
                {cancellationResult?.message &&
                  getVenueError(config?.client || ConfigClientEnum.sn)[cancellationResult.message]}
              </div>
            )}
            <div className="order-data__title">ORDER DETAILS</div>
          </div>

          <div className="order-data__content">
            <div className="order-data__table">
              <div className="order-data__table_item">
                <div className="order-data__table_label">Order #</div>
                <div>{orderData?.orderId}</div>
              </div>

              <div className="order-data__table_item">
                <div className="order-data__table_label">Customer Name</div>
                <div>
                  {orderData?.guestFirstName} {orderData?.guestLastName}
                </div>
              </div>

              <div className="order-data__table_item">
                <div className="order-data__table_label">Order Date</div>
                <div>{orderData?.orderDate && format(new Date(orderData.orderDate), "MM/dd/yyyy h:mm a")}</div>
              </div>

              <div className="order-data__table_item">
                <div className="order-data__table_label">Confirmation #</div>
                <div>{orderData?.orderConfirmation}</div>
              </div>

              <div className="order-data__table_item">
                <div className="order-data__table_label">Visit Date</div>
                <div>{orderData?.visitDate && format(new Date(orderData.visitDate), "MM/dd/yyyy h:mm a")}</div>
              </div>

              <div className="order-data__table_item">
                <div className="order-data__table_label">Number of Tickets</div>
                <div>{orderData?.numberOfTickets}</div>
              </div>
            </div>
          </div>

          <div className="order-data__tickets">
            <div className="order-data__tickets_row order-data__tickets_row_head">
              <div className="order-data__tickets_label">Qty</div>
              <div>Type</div>
            </div>

            {orderData?.orderProducts.map((ticket: IOrderProducts) => (
              <div key={ticket.productName} className="order-data__tickets_row">
                <div className="order-data__tickets_label">{ticket.qty}</div>
                <div className="order-data__tickets_name">{ticket.productName}</div>
              </div>
            ))}
          </div>

          <div className="order-data__controls">
            {cancellationResult?.message !== CancellationResultEnum.NOT_REFUNDABLE && (
              <div className="order-data__terms">
                <label className="order-data__terms_label">
                  <input
                    type="checkbox"
                    className="order-data__terms_control"
                    checked={isTermsAgreed}
                    onChange={handleTermsClick}
                  />
                  Please cancel and refund my ticket order. My refund will be received in 5-10 business days.
                </label>
              </div>
            )}

            <div className="order-data__control_wrapper">
              <button className="order-data__control order-data__control_secondary" onClick={resetOrderData}>
                BACK
              </button>

              {cancellationResult?.message !== CancellationResultEnum.NOT_REFUNDABLE && (
                <button
                  className={`
                    order-data__control
                    ${!isTermsAgreed && "order-data__control_disabled"}
                  `}
                  onClick={cancelBookingHandler}
                  disabled={!isTermsAgreed}
                >
                  CANCEL TICKETS
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderData;
