import { ConfigClientEnum } from "@interfaces/enums/apiData.enum";
import { IVenueEmail, IVenueEmailsConfig } from "@pages/selfService/components/orderData/orderData.types";

const snEmail: IVenueEmail = {
  href: `mailto:guestservice@spaceneedle.com?subject=Self%20Service%20Cancellation%20\
Assistance&body=Please%20include%20your%20order%20ID%20#%20for%20faster%20service.%20Thanks!`,
  address: "guestservice@spaceneedle.com",
};

const cggEmail: IVenueEmail = {
  href: `mailto:guestservice@chihulygardenandglass.com?subject=Self%20Service%20Cancellation%20\
Assistance&body=Please%20include%20your%20order%20ID%20#%20for%20faster%20service.%20Thanks!`,
  address: "guestservice@chihulygardenandglass.com",
};

export const venueEmails: IVenueEmailsConfig = {
  [ConfigClientEnum.sn]: snEmail,
  [ConfigClientEnum.cgg]: cggEmail,
};
