import { ProductGroupNameEnum } from "@interfaces/enums/tabName.enum";
import { IAppliedPromotionsCitypass } from "@pages/cart/provider/cart.types";
import {
  IProductDto,
  IProductUpgrades,
  IProductAttributes,
  IProductUpgradesFiltered,
  ITermsAndConditions,
} from "../../pages/productsPreview/provider/productsList.types";
import ProductsListDto from "../rest/productsList.dto";

interface IProductProps {
  product: IProductDto;
  fullProductList: IProductDto[];
  productCategoryName: ProductGroupNameEnum;
  isHidden?: boolean;
}

export class ProductUI {
  public appliedPromotions?: IAppliedPromotionsCitypass[] | null;

  public productId: number;

  public name: string;

  public shortDescription: string;

  public longDescription: string;

  public productTypeId: number;

  public imageUrl: string;

  public displayLabel: string | null;

  public additionalInformation: string;

  public productUpgrades: IProductUpgradesFiltered[] | null;

  public originalProductUpgrades: IProductUpgrades[] | null;

  public productAttributes: IProductAttributes[];

  public skipVisitDateSelection: boolean | null;

  public systemRedeemableOnly: boolean;

  public maxTicketsAllowedPerOrder: number | null;

  public maxTicketsAllowedPerOrderMsg: string | null;

  public rollingDaysCount: number;

  public groupSalesAdvPurchaseDayCount: number;

  public groupSalesMinQty: number;

  public groupSalesMinRuleQty: number;

  public groupSalesRewardQty: number;

  public voucherId: string | null;

  public isVoucher: boolean;

  public promotionId: number;

  public emailExchangeButtonLabel: string | null;

  public disclosure: string | null;

  public productCategoryName: ProductGroupNameEnum;

  public isHidden?: boolean;

  public priceMessage: string | null;

  public isPriceMessageEnabled: boolean;

  constructor(data: IProductProps) {
    this.appliedPromotions = data.product.appliedPromotions;
    this.productId = data.product.productId;
    this.name = data.product.name;
    this.shortDescription = data.product.shortDescription;
    this.longDescription = data.product.longDescription;
    this.productTypeId = data.product.productTypeId;
    this.imageUrl = data.product.imageUrl;
    this.displayLabel = data.product.displayLabel;
    this.additionalInformation = data.product.additionalInformation;
    this.productUpgrades = this.formActualProductUpgrades(data.product.productUpgrades, data.fullProductList);
    this.originalProductUpgrades = data.product.productUpgrades;
    this.productAttributes = data.product.productAttributes;
    this.skipVisitDateSelection = data.product.skipVisitDateSelection;
    this.systemRedeemableOnly = data.product.systemRedeemableOnly;
    this.maxTicketsAllowedPerOrder = data.product.maxTicketsAllowedPerOrder;
    this.maxTicketsAllowedPerOrderMsg = data.product.maxTicketsAllowedPerOrderMsg;
    this.rollingDaysCount = data.product.rollingDaysCount;
    this.groupSalesAdvPurchaseDayCount = data.product.groupSalesAdvPurchaseDayCount;
    this.groupSalesMinQty = data.product.groupSalesMinQty;
    this.groupSalesMinRuleQty = data.product.groupSalesMinRuleQty;
    this.groupSalesRewardQty = data.product.groupSalesRewardQty;
    this.voucherId = data.product.voucherId;
    this.isVoucher = data.product.isVoucher;
    this.promotionId = data.product.promotionId;
    this.emailExchangeButtonLabel = data.product.emailExchangeButtonLabel;
    this.disclosure = data.product.disclosure;
    this.productCategoryName = data.productCategoryName;
    this.isHidden = data.isHidden;
    this.priceMessage = data.product.priceMessage;
    this.isPriceMessageEnabled = data.product.isPriceMessageEnabled;
  }

  private formActualProductUpgrades(
    productUpgradesList: IProductUpgrades[] | null,
    fullProductList: IProductDto[],
  ): IProductUpgradesFiltered[] | null {
    return !productUpgradesList
      ? null
      : productUpgradesList.reduce((prev: IProductUpgradesFiltered[], cur: IProductUpgrades) => {
          const product: IProductDto | undefined = fullProductList.find(
            (item: IProductDto) => item.productId === cur.upgradeProductId,
          );

          return !product
            ? prev
            : [
                ...prev,
                {
                  id: cur.upgradeProductId,
                  name: product.name,
                  headline: cur.headline,
                  title: cur.promotionalMessage,
                  longDescription: cur.longDescription,
                  imageUrl: cur.imageUrl,
                },
              ];
        }, []);
  }

  getProductInstructions(): string[] | null {
    const instructionsAttrs: IProductAttributes[] = this.productAttributes.filter((attr: IProductAttributes) =>
      /instruction/gi.test(attr.attributeName),
    );

    const instructionsArray: string[] = instructionsAttrs.reduce(
      (array: string[], attribute: IProductAttributes) =>
        attribute.attributeValue ? [...array, attribute.attributeValue] : array,
      [],
    );

    return instructionsArray.length ? instructionsArray : null;
  }

  getTermsAndConditions(): ITermsAndConditions | null {
    const termsAndCondLabelObj: IProductAttributes = this.productAttributes.filter((attr: IProductAttributes) =>
      /termsandconditionslabel/gi.test(attr.attributeName),
    )[0];

    if (!termsAndCondLabelObj) {
      return null;
    }

    const termsAndCondUrlObj: IProductAttributes = this.productAttributes.filter((attr: IProductAttributes) =>
      /termsandconditionsurl/gi.test(attr.attributeName),
    )[0];

    return {
      label: termsAndCondLabelObj.attributeValue || "",
      link: termsAndCondUrlObj.attributeValue || "",
    };
  }

  formProductBackToDto(): IProductDto {
    return {
      productId: this.productId,
      name: this.name,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      productTypeId: this.productTypeId,
      imageUrl: this.imageUrl,
      displayLabel: this.displayLabel,
      additionalInformation: this.additionalInformation,
      productUpgrades: this.originalProductUpgrades,
      productAttributes: this.productAttributes,
      skipVisitDateSelection: this.skipVisitDateSelection,
      systemRedeemableOnly: this.systemRedeemableOnly,
      maxTicketsAllowedPerOrder: this.maxTicketsAllowedPerOrder,
      maxTicketsAllowedPerOrderMsg: this.maxTicketsAllowedPerOrderMsg,
      rollingDaysCount: this.rollingDaysCount,
      groupSalesAdvPurchaseDayCount: this.groupSalesAdvPurchaseDayCount,
      groupSalesMinQty: this.groupSalesMinQty,
      groupSalesMinRuleQty: this.groupSalesMinRuleQty,
      groupSalesRewardQty: this.groupSalesRewardQty,
      voucherId: this.voucherId,
      isVoucher: this.isVoucher,
      promotionId: this.promotionId,
      emailExchangeButtonLabel: this.emailExchangeButtonLabel,
      disclosure: this.disclosure,
      priceMessage: this.priceMessage,
      isPriceMessageEnabled: this.isPriceMessageEnabled,
    };
  }
}

class ProductsListUI {
  public products: ProductUI[];

  public productsEvents: ProductUI[];

  public productsGroups: ProductUI[];

  public productsOtherAttractions: ProductUI[];

  public promoCodeProducts: ProductUI[] | null;

  constructor(data?: ProductsListDto) {
    if (data) {
      this.products = data.products.map(
        (product: IProductDto) =>
          new ProductUI({
            product,
            productCategoryName: ProductGroupNameEnum.products,
            fullProductList: data.products,
          }),
      );
      this.productsOtherAttractions = data.productsOtherAttractions.map(
        (product: IProductDto) =>
          new ProductUI({
            product,
            productCategoryName: ProductGroupNameEnum.productsOtherAttractions,
            fullProductList: data.productsOtherAttractions,
          }),
      );
      this.productsEvents = data.productsEvents.map(
        (product: IProductDto) =>
          new ProductUI({
            product,
            productCategoryName: ProductGroupNameEnum.productsEvents,
            fullProductList: data.productsEvents,
          }),
      );
      this.productsGroups = data.productsGroups.map(
        (product: IProductDto) =>
          new ProductUI({
            product,
            productCategoryName: ProductGroupNameEnum.productsGroups,
            fullProductList: data.productsGroups,
          }),
      );
      this.promoCodeProducts = null;
    }
  }
}

export default ProductsListUI;
