import { ReactNode, useCallback, useState } from "react";
import useAxios from "axios-hooks";

import api from "@api/index";
import SelfServiceDto, { CancelResultDto } from "@interfaces/rest/selfService.dto";
import SelfServiceOrderData from "@interfaces/ui/selfServiceOrderData.ui";
import useConfig from "@providers/configProvider/useConfig";

import { ConfigClientEnum } from "@interfaces/enums/apiData.enum";
import { ISefleServiceState, ICancellationResult, CancellationResultEnum } from "./selfService.types";
import { getVenueError } from "../components/orderData/OrderData";

const useSelfServiceState = (): ISefleServiceState => {
  const { config } = useConfig();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orderNotFoundError, setOrderNotFoundError] = useState<ReactNode>("");
  const [orderData, setOrderData] = useState<SelfServiceOrderData | null>(null);
  const [cancellationResult, setCancellationResult] = useState<ICancellationResult | null>(null);

  const [{}, fetchOrderData] = useAxios<SelfServiceDto>(
    {
      method: "get",
    },
    { manual: true },
  );

  const [{}, postOrderCancel] = useAxios<boolean | CancelResultDto>(
    {
      url: api.orderRefund,
      method: "post",
    },
    { manual: true },
  );

  const getOderData = useCallback(async (orderId: string, email: string): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await fetchOrderData({
        url: api.orderData(orderId, email),
      });
      const data: SelfServiceOrderData = new SelfServiceOrderData({ ...response.data, email });
      setOrderData(data);
      setIsLoading(false);
    } catch (error: any) {
      setOrderNotFoundError(
        error?.response?.data?.Code === 17
          ? "Your order was not found. Please review your details and try again."
          : getVenueError(config?.client || ConfigClientEnum.sn)[CancellationResultEnum.ERROR],
      );
      setIsLoading(false);
    }
  }, []);

  const resetCancellationResult = useCallback(() => {
    setCancellationResult(null);
  }, []);

  const cancelBooking = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    resetCancellationResult();
    try {
      await postOrderCancel({
        data: {
          orderId: orderData?.orderId,
          email: orderData?.email,
        },
      });
      setCancellationResult({
        isError: false,
      });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setCancellationResult({
        isError: true,
        message:
          error?.response?.data?.Code === 5005 ? CancellationResultEnum.NOT_REFUNDABLE : CancellationResultEnum.ERROR,
      });
    }
  }, [orderData]);

  const resetOrderData = useCallback(() => {
    setOrderData(null);
  }, []);

  const resetOrderNotFoundModal = useCallback(() => {
    setOrderNotFoundError("");
  }, []);

  return {
    isLoading,
    orderData,
    orderNotFoundError,
    getOderData,
    resetOrderData,
    resetOrderNotFoundModal,
    cancelBooking,
    cancellationResult,
    resetCancellationResult,
  };
};

export default useSelfServiceState;
